/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Blocks
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "sass-mq/mq";
@use "../settings/spacings";
@use "../settings/colors";
@use "../tools/global";

/*******************************************************************************
 * Variables
*******************************************************************************/

$blocks-gutter-start: spacings.size(6) !default;
$blocks-gutter-end: spacings.size(12) !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-blocks {
  position: relative;
  width: 100%;
}

  .o-blocks__item {
    position: relative;
    z-index: 1;
    @include global.fluid-size($blocks-gutter-start, $blocks-gutter-end, --blocks-item-space);
    padding: var(--blocks-item-space) 0;

    &.o-blocks__item--large {
      @include global.fluid-size($blocks-gutter-start * 2, $blocks-gutter-end * 2, --blocks-item-space);

      &.o-blocks__item--decoration {
        &::before {
          @include global.fluid-size(spacings.size(6), spacings.size(12), bottom);
        }
      }
    }

    &.o-blocks__item--scooter {
      --scooter-color: #{ colors.get("secondary-50") };

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: var(--scooter-color);
        border-radius: rem.convert(20px 0 0 20px);
      }
    }

    &.o-blocks__item--collapse {
      padding-top: 0;
    }
  }

/*******************************************************************************
 * States
*******************************************************************************/

body.is-mounted .o-blocks {
  .o-blocks__item--decoration,
  .o-blocks__item--decoration-double {
    --decoration-color: #{ colors.get("primary-300") };

    overflow: hidden;

    &::before {
      @supports (clip-path: url(#decoration-simple)) {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: -1;
        width: 100%;
        min-width: rem.convert(1600px);
        height: rem.convert(164px);
        background-color: var(--decoration-color);
        transform: translateX(-50%);
        clip-path: url(#decoration-simple);
      }
    }
  }

  .o-blocks__item--decoration-double {
    &::before {
      @supports (clip-path: url(#decoration-double)) {
        bottom: 50%;
        height: rem.convert(184px);
        transform: translate(-50%, 75%);
        clip-path: url(#decoration-double);
      }
    }
  }
}

/*******************************************************************************
 * Variants
*******************************************************************************/

.o-blocks--large {
  > .o-blocks__item {
    position: relative;
    z-index: 1;
    @include global.fluid-size($blocks-gutter-start * 2, $blocks-gutter-end * 2, --blocks-item-space);
    padding-right: 0;
    padding-left: 0;

    &.o-blocks__item--decoration {
      &::before {
        @include global.fluid-size(spacings.size(6), spacings.size(12), bottom);
      }
    }
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq.mq($from: giant) {
  .o-blocks__item {
    &.o-blocks__item--scooter {
      &::after {
        left: rem.convert(spacings.size(25));
      }
    }
  }
}
