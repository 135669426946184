/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Jumble
 * Type: Object
 **/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/spacings";
@use "../tools/global";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-jumble {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: rem.convert(spacings.size(18));
  row-gap: rem.convert(spacings.size(6));
}

  .o-jumble__head {
    flex-basis: rem.convert(380px);
    flex-grow: 1;
    width: rem.convert(380px);
    max-width: 100%;

    > * {
      max-width: rem.convert(600px);
    }
  }

  .o-jumble__body {
    display: flex;
    flex-basis: 0;
    flex-wrap: wrap;
    flex-grow: 999;
    min-width: 60%;
    padding: 0;
    margin: 0;
    list-style: none;
    @include global.fluid-size(spacings.size(8), spacings.size(12), gap);
  }

    .o-jumble__item {
      display: block;
      flex: 1 1 auto;
      min-width: rem.convert(250px);
    }
