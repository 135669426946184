/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Optionlist
 * Type: Component
 * Description:
 *  List of options.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-optionlist {}

  .c-form-optionlist__items {
    margin: rem.convert(-4px -8px);

    .c-form-optionlist--inline & {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .c-form-optionlist__item {
    padding: rem.convert(4px 8px);
  }
