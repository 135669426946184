/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Footer
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";
@use "sass-rem/rem";
@use "sass-em/em";
@use "sass-mq/mq";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/global";
@use "../settings/spacings";
@use "../tools/global" as tools;
@use "../tools/typography" as typo;
@use "@lunaweb/vega-reactor-scss/src/tools/svg";

/*******************************************************************************
 * Variables
*******************************************************************************/

$footer-font-size: fonts.$small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-footer {
  container-name: footer;
  container-type: inline-size;

  overflow: hidden;
  font-size: rem.convert($footer-font-size);
  color: colors.get("foreground");
  background-color: colors.get("background");
}

  .c-footer__wrapper {}

    .c-footer__head,
    .c-footer__body {
      @include tools.fluid-size(spacings.size(6), spacings.size(12), padding);
      padding-right: 0;
      padding-left: 0;
    }

    .c-footer__head {
      display: grid;
      row-gap: rem.convert(spacings.size(6));
      column-gap: rem.convert(spacings.size(12));
      grid-template:
        "logos" auto
        "partners" auto
        "eco" auto / 1fr;
    }

      .c-footer__logos {
        grid-area: logos;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: rem.convert(spacings.size(12));
      }

        .c-footer__home {
          font-size: rem.convert(46px);
          color: colors.get("primary-500");
        }

        .c-footer__logo {}

      .c-footer__block {
        > * {
          margin-top: 0;
          margin-bottom: 0;

          + * {
            margin-top: rem.convert(spacings.size(4));
          }
        }

        &.c-footer__block--partners {
          grid-area: partners;
        }

        &.c-footer__block--eco {
          grid-area: eco;
        }
      }

        .c-footer__title {
          font-family: fonts.$primary;
          font-size: fonts.$base;
          font-weight: fonts.$bold;

          @if fonts.$use-vfont {
            font-variation-settings: "wdth" 125;
          }

          &.c-footer__title--main {
            @include typo.h4;
          }

          &.c-footer__title--section {
            text-transform: uppercase;
          }
        }

        .c-footer__items {
          display: flex;
          flex-wrap: wrap;
          gap: rem.convert(spacings.size(4));
          padding: 0;
          list-style-type: none;

          &.c-footer__items--column {
            display: block;
            columns: rem.convert(200px) auto;
            column-gap: rem.convert(spacings.size(12));
          }
        }

          .c-footer__item {
            position: relative;
            padding-left: rem.convert(spacings.size(4));

            &::before {
              content: "";
              position: absolute;
              top: em.convert(3px, 16px);
              left: 0;
              width: 1em;
              height: 1em;
              font-size: em.convert(16px, $footer-font-size);
              background-image: svg.icon(map.get(global.$icons, "chevron-right"), map.get(colors.$functionals, "secondary"));
              background-repeat: no-repeat;
              background-position: center;
              background-size: 1em;
            }

            .c-footer__items.c-footer__items--column > & + .c-footer__item {
              margin-top: rem.convert(spacings.size(2));
            }
          }

        .c-footer__buttons {
          display: flex;
          flex-wrap: wrap;
          gap: rem.convert(8px);
        }

          .c-footer__button {}

    .c-footer__body {
      display: grid;
      row-gap: rem.convert(spacings.size(6));
      column-gap: rem.convert(spacings.size(12));
      grid-template:
        "logo" auto
        "navigation" auto / 1fr;
      border-top: rem.convert(1px) solid colors.get("primary-100");
    }

      .c-footer__navigation {
        grid-area: navigation;
        display: flex;
        flex-wrap: wrap;
        @include tools.fluid-size(spacings.size(6), spacings.size(12), gap);

        > * {
          flex: 1 0 auto;
          min-width: rem.convert(256px);
          max-width: 100%;
        }
      }

        .c-footer__section {
          > * {
            margin-top: 0;
            margin-bottom: 0;

            + * {
              margin-top: rem.convert(spacings.size(4));
            }
          }
        }

    .c-footer__foot {
      padding: spacings.size(6) 0;
      text-align: center;
    }

    .c-footer__gimmick {
      position: absolute;
      right: rem.convert(spacings.size(-26));
      bottom: rem.convert(spacings.size(-24));
      z-index: -1;
      font-size: clamp(rem.convert(475px), 50cqw, rem.convert(700px));
      color: colors.get("primary-50");
      opacity: 0.5;
    }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq.mq($from: small) {
  .c-footer__head {
    grid-template:
      "logos logos" auto
      "partners eco" auto / 1fr 1fr;
  }
}

@include mq.mq($from: large) {
  .c-footer__head {
    grid-template: "logos partners eco" auto / rem.convert(120px) 2fr 1fr;
  }

  .c-footer__body {
    grid-template: "logo navigation" auto / rem.convert(120px) 1fr;
  }
}
