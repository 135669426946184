/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Grid
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "sass-mq/mq";
@use "../settings/spacings";
@use "../tools/global";

/*******************************************************************************
 * Variables
*******************************************************************************/

$grid-min: 300px;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-grid {
  --min: #{ rem.convert($grid-min) };
  --max: 1fr;
  @include global.fluid-size(spacings.size(4), spacings.size(6), --gap);

  display: grid;
  padding: 0;
  margin: 0;
  list-style: none;
  grid-gap: var(--gap);

  @supports (grid-template-columns: repeat(auto-fit, minmax(min(var(--min), 100%), var(--max)))) {
    grid-template-columns: repeat(auto-fit, minmax(min(var(--min), 100%), var(--max)));
  }
}

  .o-grid__item {
    &.o-grid__item--full {
      grid-column: 1 / -1;
    }
  }

/*******************************************************************************
 * Variants
*******************************************************************************/

.o-grid--stretch {
  > .o-grid__item {
    display: flex;
    flex-direction: column;

    > * {
      width: 100%;
      height: 100%;
    }
  }
}

.o-grid--few {
  --max: max-content;
}

.o-grid--album {
  --gap: 0;

  grid-template-columns: 1fr 1fr;
  aspect-ratio: 16 / 9;

  > :nth-child(3n) {
    grid-column: 2;
    grid-row: 1 / span 2;
  }

  @include mq.mq($from: huge) {
    --gap: #{ rem.convert(spacings.size(3)) };
  }
}

.o-grid--block-end {
  align-items: end;
}
