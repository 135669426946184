/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Details
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";
@use "sass-em/em";
@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/spacings";
@use "../tools/global";
@use "../tools/typography" as typo;
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Variables
*******************************************************************************/

$details-font-size: fonts.$base !default;
$details-foreground: #{ colors.get("foreground") } !default;
$details-background: #{ colors.get("background")} !default;
$details-border-size: 1px !default;
$details-border-color: #{ colors.get("primary-200")} !default;
$details-border-radius: 20px !default;
$details-gap: #{ spacings.size(4) } !default;
$details-icon-size: 20px !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-details {
  container-name: details;
  container-type: inline-size;

  font-size: rem.convert($details-font-size);
  color: $details-foreground;
  background-color: $details-background;
  border: rem.convert($details-border-size) solid $details-border-color;
  border-radius: em.convert($details-border-radius, $details-font-size);
}

  .c-details__summary {
    box-sizing: border-box;
    display: block;
    margin: 0;
    font-size: inherit;
    border-radius: em.convert($details-border-radius - $details-border-size, $details-font-size);

    &::-webkit-details-marker,
    &::after {
      display: none;
    }

    // Firefox
    &::marker {
      font-size: 0;
    }

    > * {
      margin: 0;
    }
  }

    .c-details__control {
      position: relative;
      display: grid;
      grid-template:
        "title" auto
        "subtitle" auto
        "label" auto / 1fr;
      align-items: center;
      width: 100%;
      margin: 0;
      @include global.fluid-size(spacings.size(4), spacings.size(12), padding);
      column-gap: em.convert($details-gap, $details-font-size);
      row-gap: em.convert(8px, $details-font-size);
      font: inherit;
      text-align: inherit;
      appearance: none;
      color: inherit;
      background-color: transparent;
      border: 0;
      border-radius: em.convert($details-border-radius - $details-border-size, $details-font-size);
    }

      .c-details__title {
        grid-area: title;
        flex: 1 1 auto;
        @include typo.h3;
        font-weight: fonts.$bold;
      }

      .c-details__subtitle {
        grid-area: subtitle;
      }

      .c-details__label {
        @include animation.transition(text-decoration-color);
        grid-area: label;
        display: inline-flex;
        align-items: center;
        gap: rem.convert(spacings.size(2));
        margin-top: rem.convert(spacings.size(2));
        font-weight: fonts.$semibold;
        color: colors.get("primary");
        text-decoration-line: underline;

        &:hover {
          text-decoration-color: transparent;
        }
      }

      .c-details__marker {
        position: relative;
        display: inline-block;
        width: em.convert(18px, $details-font-size);
        height: em.convert(18px, $details-font-size);
        @include animation.transition(transform);

        .c-details__label:hover & {
          transform: rotate(0.5turn);
        }

        &::before,
        &::after {
          --rotate: 0;

          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: em.convert(2px, $details-font-size);
          background-color: colors.get("secondary");
          border-radius: em.convert(2px, $details-font-size);
          @include animation.transition(transform);
          transform: translate(-50%, -50%) rotate(var(--rotate));

          @supports (clip-path: url(#line-1)) {
            border-radius: 0;
            clip-path: url(#line-1);
          }
        }

        &::after {
          --rotate: 0.25turn;

          @supports (clip-path: url(#line-2)) {
            clip-path: url(#line-2);
          }
        }
      }

  .c-details__content {
    @include global.fluid-size(spacings.size(4), spacings.size(12), padding);
    padding-top: 0;
  }

/*******************************************************************************
 * States
*******************************************************************************/

/**
 * Default
*******************************************************************************/

details.c-details,
.c-details.is-mounted {
  .c-details__control {
    cursor: pointer;
  }

  .c-details__summary ~ * {
    animation: details-open 200ms ease-in-out;
  }
}

.c-details:not(details):not(.is-mounted) {
  .c-details__label {
    display: none;
  }
}

/**
 * Open
*******************************************************************************/

/**
 * Details
********************/

.c-details[open] {
  .c-details__summary ~ * {
    display: block;
  }

    .c-details__marker::after {
      --rotate: 0.5turn;
    }
}

/**
 * JavaScript
********************/

.c-details.is-mounted {
  .c-details__control[aria-expanded="true"],
  .c-details__content:not([hidden]) {
    .c-details__marker::after {
      --rotate: 0.5turn;
    }
  }
}

/**
 * Close
*******************************************************************************/

/**
 * JavaScript
********************/

.c-details.is-mounted {
  .c-details__content[hidden] {
    display: none;
    padding-bottom: 0;

    @supports (content-visibility: hidden) {
      display: block;
      content-visibility: hidden;
    }
  }
}

/*******************************************************************************
 * Container Queries
*******************************************************************************/

@container details (min-width: 36em) {
  .c-details__control {
    grid-template:
      "title label" auto
      "subtitle label" auto / 1fr auto;
  }

    .c-details__label {
      margin: 0;
    }
}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes details-open {
  from {
    opacity: 0;
    transform: translate3d(0, -1rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-details__summary {
    break-inside: avoid;

    .c-details__marker {
      &::before,
      &::after {
        background-color: currentcolor !important;
      }
    }
  }
}
