/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: External
 * Type: Utilities
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";
@use "../settings/colors";
@use "../settings/global";
@use "@lunaweb/vega-reactor-scss/src/tools/svg";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.u-external {
  &::after {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right: 0.15ch;
    margin-left: 0.5ch;
    font-size: 0.85em;
    line-height: 0.8;
    vertical-align: -0.125em;
    background-image: svg.icon(map.get(global.$icons, "external"), map.get(colors.$functionals, "foreground"));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1em;
  }
}

.u-external-revert {
  &::after {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right: 0.15ch;
    margin-left: 0.5ch;
    font-size: 0.85em;
    line-height: 0.8;
    vertical-align: -0.125em;
    background-image: svg.icon(map.get(global.$icons, "external"), map.get(colors.$functionals, "foreground-revert"));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1em;
  }
}
