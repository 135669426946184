/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Title
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "../tools/typography" as typo;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-title {
  --icon-color: currentcolor;

  @include typo.title("title");

  position: relative;
}

  .c-title__icon {
    margin-right: 0.5ch;
    color: var(--icon-color);
  }

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-title--static {
  position: static;
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-title {
    break-inside: avoid;
  }
}
