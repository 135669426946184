/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Typography
 * Type: Generic
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

* {
  text-decoration-thickness: 0.0714em;
  text-underline-position: from-font;
  text-underline-offset: 0.0714em;
}
