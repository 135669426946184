/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Scroll
 * Type: Generic
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/spacings";

/*******************************************************************************
 * Default styles
*******************************************************************************/

[id] {
  scroll-margin-top: calc(var(--header-main-height) + #{ rem.convert(spacings.size(2)) });

  .admin-bar & {
    scroll-margin-top: calc(var(--header-main-height) + #{ rem.convert(spacings.size(2) + 50px) });
  }
}
