/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Hidden
 * Type: Utilities
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "@lunaweb/vega-reactor-scss/src/tools/hide";

/*******************************************************************************
 * Default styles
*******************************************************************************/

// Hide only visually, but have it available for screen readers:
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility

.u-hidden-visually {
  @include hide.visually;
}
