/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Panel
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "sass-mq/mq";
@use "../settings/colors";
@use "../settings/spacings";
@use "../tools/global";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-panel {
  --panel-color: #{ colors.get("foreground") };
  --panel-background: #{ colors.get("background") };
  --panel-border: #{ colors.get("primary-200") };
  --panel-gimmick: #{ colors.get("primary-50") };

  container-name: panel;
  container-type: inline-size;
  position: relative;
  z-index: 1;
  padding: rem.convert(spacings.size(2));
  overflow: hidden;
  color: var(--panel-color);
  background-color: var(--panel-background);
  border: rem.convert(1px) solid var(--panel-border);
  border-radius: rem.convert(20px);
}

  .c-panel__container {
    @include global.fluid-size(spacings.size(2), spacings.size(7), padding);
  }

    .c-panel__body,
    .c-panel__foot {
      @include global.fluid-size(spacings.size(2), spacings.size(3), padding);
    }

    .c-panel__body {}

    .c-panel__foot {}

    .c-panel__gimmick {
      position: absolute;
      right: rem.convert(spacings.size(-6));
      bottom: rem.convert(spacings.size(-5));
      z-index: -1;
      font-size: clamp(rem.convert(175px), 50cqw, rem.convert(400px));
      color: var(--panel-gimmick);
      opacity: 0.5;
    }

/*******************************************************************************
 * Container Queries
*******************************************************************************/

@container panel (min-width: 30em) {
  .c-panel__gimmick {
    top: 50%;
    right: 0;
    bottom: auto;
    transform: translate(20%, -50%);
  }
}


/*******************************************************************************
 * Variants
*******************************************************************************/

.c-panel--light,
.c-panel--dark {
  border: 0;
}

.c-panel--light {
  --panel-background: #{ colors.get("primary-50") };
  --panel-gimmick: #{ colors.get("primary-200") };
}

.c-panel--dark {
  --panel-color: #{ colors.get("foreground-revert") };
  --panel-background: #{ colors.get("primary-800") };
  --panel-gimmick: #{ colors.get("primary-700") };
}

// .c-panel--no-radius {
//   border: 0;
// }

.c-panel--radius-block-start {
  border-radius: rem.convert(20px) 0 0 rem.convert(20px);
}

.c-panel--radius-block-end {
  border-radius: 0 rem.convert(20px) rem.convert(20px) 0;
}

.c-panel--hybrid {
  @include mq.mq($until: large) {
    border: 0;
    border-radius: 0;
  }
}

.c-panel--narrow {
  .c-panel__container {
    @include global.fluid-size(spacings.size(0), spacings.size(3), padding);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-panel {
    break-inside: avoid;
    color: #000 !important;
    background-color: #fff !important;
    border-color: #000 !important;

    .c-panel__gimmick {
      opacity: 0.1;
    }

    &.c-panel--dark,
    &.c-panel--light {
      border: rem.convert(1px) solid !important;
    }
  }
}
