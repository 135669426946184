/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Action
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-em/em";
@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/spacings";

/*******************************************************************************
 * Variables
*******************************************************************************/

$action-font-size: fonts.$small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-action {
  display: flex;
  align-items: center;
  gap: em.convert(spacings.size(2), $action-font-size);
  font-size: rem.convert($action-font-size);
}

  .c-action__icon {
    flex: 0 0 auto;
    padding: em.convert(3px 7px, 18px);
    font-size: em.convert(18px, $action-font-size);
    line-height: 0;
    color: colors.get("foreground-revert");
    background-color: colors.get("background-revert");
    border-radius: 1em;
  }

  .c-action__link {
    flex: 0 1 auto;
    line-height: 1.2;
    text-align: inherit;
    color: inherit;
  }

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-action {
    break-inside: avoid;

    .c-action__icon {
      background-color: #000 !important;
    }
  }
}
