/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Main
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-mq/mq";
@use "sass-rem/rem";
@use '../settings/colors';
@use '../settings/spacings';
@use '../tools/global';
@use '../objects/wrapper';

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-main {}

  .c-main__head {
    --color: #{ colors.get("foreground-revert") };
    --background: #{ colors.get("background-revert") };

    color: var(--color);
    background-color: var(--background);
  }

  .c-main__overlap {
    margin-top: calc(-1 * #{ global.fluid-size-value(spacings.size(0), spacings.size(8)) });
  }

  .c-main__aside {
    padding: 0;
  }

  .c-main__body {
    @include global.fluid-size(spacings.size(8), spacings.size(12), padding-top);
    @include global.fluid-size(spacings.size(6), spacings.size(12), padding-bottom);
  }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq.mq($until: small) {
  .c-main__overlap .c-main__wrapper {
    --gutter: 0;
  }
}

@include mq.mq($from: large) {
  .c-main__overlap {
    margin-top: rem.convert(spacings.size(-8));
  }
}

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-main--column {
  --main-aside-size: #{ rem.convert(464px) };
  --main-gap: #{ rem.convert(spacings.size(15)) };

  position: relative;
  display: grid;

  @include mq.mq($until: large) {
    .c-main__body {
      padding-top: 0;
    }
  }

  @include mq.mq($from: large) {
    .c-main__head,
    .c-main__overlap,
    .c-main__body {
      padding-right: calc(var(--main-aside-size) + var(--main-gap) - #{ wrapper.$gutter * 2 });

      > * {
        max-width: rem.convert(768px);
        margin-right: 0;
        margin-left: auto;
      }
    }

    .c-main__overlap {
      .c-main__wrapper {
        box-sizing: border-box;
        width: 100%;
        padding-right: 0;
      }
    }

    .c-main__aside {
      position: absolute;
      top: rem.convert(200px);
      right: 0;
      bottom: 0;
      width: var(--main-aside-size);
      padding-right: rem.convert(spacings.size(8));
      padding-left: rem.convert(spacings.size(16));
      @include global.fluid-size(spacings.size(6), spacings.size(24), padding-bottom);

      > * {
        position: sticky;
        top: calc(var(--header-main-height) + var(--main-gap));
      }
    }
  }

  @include mq.mq($from: huge) {
    .c-main__head,
    .c-main__overlap,
    .c-main__body {
      padding-right: calc(50% - (#{ wrapper.$size * 0.5 }) + var(--main-aside-size));
    }

    .c-main__overlap {
      .c-main__wrapper {
      }
    }

    .c-main__aside {
      right: auto;
      left: calc(50% + (#{ wrapper.$size * 0.5 }) - var(--main-aside-size));
    }
  }
}

.c-main--scooter {
  @include mq.mq($from: large) {
    .c-main__head {
      position: relative;
      background-color: transparent;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        background-color: var(--background);
        border-bottom-right-radius: rem.convert(20px);
      }
    }
  }

  @include mq.mq($from: huge) {
    .c-main__head {
      &::before {
        width: calc(100% - #{ rem.convert(spacings.size(25)) });
        max-width: 100%;
      }
    }
  }
}

.c-main--narrow {
  .c-main__body {
    padding-top: 0;
  }
}

.c-main--overlap {
  .c-main__head + .c-main__body {
    padding-top: 0;
  }

  @include mq.mq($from: large) {
    .c-main__head {
      padding-bottom: rem.convert(spacings.size(26));
    }

    .c-main__overlap {
      margin-top: rem.convert(spacings.size(-34));
    }

    .c-main__head + .c-main__body {
      margin-top: rem.convert(spacings.size(-34));
    }
  }
}
