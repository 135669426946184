/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Wrapper
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "sass-mq/mq";
@use "../settings/spacings";
@use "@lunaweb/vega-reactor-scss/src/tools/objects";

/*******************************************************************************
 * Variables
*******************************************************************************/

$gutter: spacings.size(8) !default;
$size: 1200px !default;
$has-wide: true !default;
$has-stretch: true !default;

$wrappers-sizes: (
  // "nano": 210px,
  // "micro": 282px,
  "mini": 486px,
  "tiny": 600px,
  "small": 704px,
  "regular": 800px,
  "medium": 900px,
  // "large": 996px,
  // "max": 1920px,
) !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-wrapper {
  @include objects.wrapper($size, $gutter);
}

/*******************************************************************************
 * Modifiers
*******************************************************************************/

// Sizes
@each $namespace, $size in $wrappers-sizes {
  .o-wrapper--#{ $namespace } {
    --size: #{ rem.convert($size) };
  }
}

@if $has-wide {
  .o-wrapper--wide {
    --gutter: 0px;
  }
}

@if $has-stretch {
  .o-wrapper--stretch {
    > * {
        height: 100%;
    }
  }
}

.o-wrapper--start {
  margin-left: 0;
}

.o-wrapper--wide\@until-large {
  --gutter: 0;

  @include mq.mq($from: large) {
    --gutter: #{ $gutter };
  }
}

.o-wrapper--wide\@between-large-huge {
  --gutter: 0;

  @include mq.mq($from: large, $until: huge) {
    --gutter: #{ $gutter };
  }
}

.o-wrapper--triptych {
  @include mq.mq($until: medium) {
    --gutter: #{spacings.size(3)};
  }
}