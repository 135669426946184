/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: More
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/spacings";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-more {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem.convert(spacings.size(2));
  width: 100%;
  font: inherit;
  appearance: none;
  cursor: pointer;
  color: inherit;
  background-color: transparent;
  border: 0;

  &::before,
  &::after {
    content: "";
    flex: 1 1 auto;
    height: rem.convert(1px);
    background-color: colors.get("primary-100");
  }
}
