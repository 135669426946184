/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Option
 * Type: Component
 * Description: A single option respresented as a checkbox or radio button.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../../settings/colors";
@use "../../settings/fonts";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Variables
*******************************************************************************/

$option-size: 18px;
$option-gap: 8px;
$option-accent-color: #{ colors.get("primary-600") };
$option-accent-contrast-color: #{ colors.get("foreground-revert") };
$option-border-size: 2px;
$option-border-color: #{ colors.get("foreground") };
$option-disabled-background-color: #{ colors.get("neutral-100") };
$option-disabled-label-color: #{ colors.get("neutral-500") };
$option-focus-outline-size: 2px;
$option-top-alignment: 2px;
$option-radio-checked-offset: 3px;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-option {
  --option-border-color: #{$option-border-color};
  --option-background-color: #{$option-accent-contrast-color};
  --option-color: inherit;
  --option-label-color: inherit;

  display: flex;
}

  .c-form-option__control {
    position: relative;
    z-index: 1;
    flex: 0 0 auto;
  }

    .c-form-option__input,
    .c-form-option__doppel {
      display: block;
      width: rem.convert($option-size);
      height: rem.convert($option-size);
      margin-top: rem.convert($option-top-alignment);
    }

    .c-form-option__input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
    }

    .c-form-option__doppel {
      position: relative;
      color: var(--option-color);
      background: var(--option-background-color);
      border: rem.convert($option-border-size) solid var(--option-border-color);
      @include animation.transition(background box-shadow color);

      .c-form-option__input:focus ~ & {
        box-shadow:
          0 0 0 rem.convert($option-focus-outline-size) $option-accent-contrast-color,
          0 0 0 rem.convert($option-focus-outline-size * 2) $option-accent-color;
      }

      .c-form-option__input:checked ~ & {
        --option-border-color: #{ $option-accent-color };
        --option-background-color: #{ $option-accent-color };
      }
    }

      .c-form-option__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transform: translate(-50%, -50%);
        @include animation.transition(transform);

        .c-form-option__input:not(:checked) ~ .c-form-option__doppel & {
          transform: translate(-50%, -50%) scale(0);
        }
      }

  .c-form-option__details {
    flex: 1 1 auto;
    margin-left: rem.convert($option-gap);
    font-size: fonts.$small;
  }

    .c-form-option__label {
      display: block;
      font-weight: fonts.$regular;
      color: var(--option-label-color);
    }

/**
 * Checkbox
 ******************************************************************************/

.c-form-option--checkbox {
  .c-form-option__doppel {
    border-radius: 2px;
  }

  .c-form-option__input:checked ~ .c-form-option__doppel {
    --option-color: #{ $option-accent-contrast-color };
  }
}

/**
 * Radio
 ******************************************************************************/

.c-form-option--radio {
  .c-form-option__doppel {
    border-radius: 50%;

    &::before {
      content: "";
      position: absolute;
      top: rem.convert($option-radio-checked-offset);
      right: rem.convert($option-radio-checked-offset);
      bottom: rem.convert($option-radio-checked-offset);
      left: rem.convert($option-radio-checked-offset);
      background: $option-accent-contrast-color;
      border-radius: 50%;
      transform: scale(0);
      @include animation.transition(transform);
    }
  }

  .c-form-option__input:checked ~ .c-form-option__doppel {
    &::before {
      transform: scale(1);
    }
  }
}

/*******************************************************************************
 * Feedback styles
*******************************************************************************/

.c-form-option--disabled {
  --option-border-color: #{ colors.get("neutral-300") };
  --option-background-color: #{ $option-disabled-background-color };
  --option-label-color: #{ $option-disabled-label-color };

  .c-form-option__input,
  .c-form-option__doppel {
    cursor: not-allowed;
  }
}

.c-form-option--error {
  --option-background-color: #{ colors.get("error-50") };

  .c-form-option__input:not(:checked) ~ .c-form-option__doppel {
    --option-border-color: #{ colors.get("error") };
  }
}
