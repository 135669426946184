/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Icon
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-icon {
  --size: 1em;

  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: var(--size);
  line-height: 0.8em;
  vertical-align: -0.2em; // https://fvsch.com/svg-icons#section-styling
  fill: currentcolor;
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-icon {
    break-inside: avoid;
  }
}
