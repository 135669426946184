/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Vendors
 * Type: Settings
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "../settings/global";
@use "../settings/colors";
@use "../settings/fonts";
@use "sass-mq/mq" as mq with ( $breakpoints: global.$breakpoints );
@use "@lunaweb/vega-reactor-scss/src/tools/animation" with (
  $transition-duration: 400ms
);

/**
 * Tarteaucitron
*******************************************************************************/

$tac-font-default: fonts.$default; // Text font
$tac-font-primary: fonts.$primary; // Titles font
$tac-color-background: colors.get("primary-50"); // Background dialog color
$tac-color-foreground: colors.get("foreground"); // Foreground dialog color
$tac-color-error: colors.get("error"); // Error color
$tac-color-success: colors.get("success"); // Success color
$tac-color-warning: colors.get("warning"); // Warning color
$tac-color-border: colors.get("primary-200"); // Border color
$tac-border-radius: 20px; // Border radius
