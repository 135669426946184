/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Button
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";
@use "sass-em/em";
@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";
@use "@lunaweb/vega-reactor-scss/src/tools/hide";
@use "@lunaweb/vega-reactor-scss/src/tools/unit";

/*******************************************************************************
 * Variables
*******************************************************************************/

$button-font-size: fonts.$small !default;
$button-line-height: 22px !default;
$button-padding-inline: 20px !default;
$button-padding-block: 10px !default;
$button-gap: 6px !default;
$button-border-width: 2px !default;
$button-radius: 40px !default;
$button-icon-size: 18px !default;

$button-has-icon: true !default;
$button-has-revert: true !default;
$button-has-primary: true !default;
$button-has-secondary: true !default;
$button-has-link: true !default;
$button-has-disabled: true !default;
$button-has-loading: true !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-button {
  --button-bg: transparent;
  --button-color: #{ colors.get("primary-800") };
  --button-border-color: currentcolor;
  --button-icon-color: #{ colors.get("secondary") };

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding:
    em.convert(($button-padding-block - $button-border-width - math.div($button-gap, 2)), $button-font-size)
    em.convert(($button-padding-inline - $button-border-width - math.div($button-gap, 2)), $button-font-size);
  font: inherit;
  font-size: var(--button-font-size, #{ rem.convert($button-font-size) });
  font-weight: fonts.$semibold;
  line-height: math.div($button-line-height, $button-font-size);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  color: var(--button-color, currentcolor);
  background: var(--button-bg, transparent);
  border: em.convert($button-border-width, $button-font-size) solid var(--button-border-color, var(--button-bg));
  border-radius: em.convert($button-radius, $button-font-size);
  @include animation.transition(background border color transform);
}

  .c-button__icon {
    flex: 0 0 auto;
    margin:
      em.convert(math.div($button-gap + $button-line-height - $button-icon-size, 2), $button-icon-size)
      em.convert(math.div($button-gap, 2), $button-icon-size);
    font-size: em.convert($button-icon-size, $button-font-size);
    color: var(--button-icon-color, var(--button-color));
    @include animation.transition(color transform);
  }

  .c-button__label {
    margin: em.convert(math.div($button-gap, 2), $button-font-size);
  }

/*******************************************************************************
 * Interactive
*******************************************************************************/

a.c-button,
button.c-button {
  cursor: pointer;

  &:hover {
    --button-color: #{ colors.get("primary-900") };
    --button-border-color: #{ colors.get("primary-800") };

    .c-button__icon {
      transform: translateX(#{ em.convert(-4px, $button-font-size) });
    }
  }

  &:active {
    --button-color: #{ colors.get("primary-900") };
    --button-border-color: currentcolor;

    transform: translateY(#{ rem.convert(1px) });
    transition-duration: 0s;

    .c-button__icon {
      transition-duration: 0s;
    }
  }
}

/*******************************************************************************
 * Variants
*******************************************************************************/

/**
 * Revert
*******************************************************************************/

@if $button-has-revert {
  .c-button--revert {
    .c-button__icon {
      order: 2;
    }
  }

  a.c-button--revert,
  button.c-button--revert {
    &:hover {
      .c-button__icon {
        transform: translateX(#{ em.convert(4px, $button-font-size) });
      }
    }
  }
}

/**
 * Icon
*******************************************************************************/

@if $button-has-icon {
  .c-button--icon {
    .c-button__label {
      @include hide.visually;
    }
  }
}

/**
 * Primary
*******************************************************************************/

@if $button-has-primary {
  .c-button--primary {
    --button-color: #{ colors.get("foreground-revert") };
    --button-bg: #{ colors.get("primary") };
    --button-border-color: transparent;
  }

  a.c-button--primary,
  button.c-button--primary {
    &:hover {
      --button-color: #{ colors.get("foreground-revert") };
      --button-bg: #{ colors.get("primary-900") };
      --button-border-color: transparent;
    }

    &:active {
      --button-color: #{ colors.get("foreground-revert") };
      --button-bg: #{ colors.get("primary-900") };
      --button-border-color: transparent;
    }
  }
}

/**
 * Primary
*******************************************************************************/

@if $button-has-secondary {
  .c-button--secondary {
    --button-color: #{ colors.get("primary-900") };
    --button-bg: #{ colors.get("primary-50") };
    --button-border-color: transparent;
    --button-icon-color: inherit;
  }

  a.c-button--secondary,
  button.c-button--secondary {
    &:hover {
      --button-color: #{ colors.get("primary-900") };
      --button-bg: #{ colors.get("primary-100") };
      --button-border-color: transparent;
    }

    &:active {
      --button-color: #{ colors.get("primary-900") };
      --button-bg: #{ colors.get("primary-100") };
      --button-border-color: transparent;
    }
  }
}

/**
 * Link
*******************************************************************************/

@if $button-has-link {
  .c-button--link {
    padding: 0;
    border: 0;

    .c-button__label {
      @include animation.transition(text-decoration-color);
      text-decoration-line: underline;
    }

    &.c-button--revert {
      .c-button__icon {
        margin-right: 0;
      }

      .c-button__label {
        margin-left: 0;
      }
    }

    &:not(.c-button--revert) {
      .c-button__icon {
        margin-left: 0;
      }

      .c-button__label {
        margin-right: 0;
      }
    }
  }

  a.c-button--link,
  button.c-button--link {
    &:hover {
      --button-color: #{ colors.get("primary-900") };

      .c-button__label {
        text-decoration-color: transparent;
      }
    }

    &:active {
      --button-color: #{ colors.get("primary") };
    }
  }
}

/*******************************************************************************
 * Disabled
*******************************************************************************/

@if $button-has-disabled {
  .c-button,
  button.c-button,
  a.c-button {
    &[disabled],
    &[aria-disabled],
    &.is-disabled {
      --button-bg: initial;
      --button-color: #{ colors.get("neutral-600")};
      --button-border-color: #{ colors.get("neutral-300") };

      pointer-events: none;

      @if $button-has-primary {
        &.c-button--primary {
          --button-color: #{ colors.get("neutral-600")};
          --button-bg: #{ colors.get("neutral-300")};
          --button-border-color: transparent;
        }
      }

      @if $button-has-link {
        &.c-button--link {
          --button-bg: initial;
          --button-color: #{ colors.get("neutral-600")};
          --button-border-color: initial;
        }
      }
    }
  }
}

/*******************************************************************************
 * Loading
*******************************************************************************/

@if $button-has-loading {
  .c-button.is-loading {
    --rotate: 0deg;

    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: em.convert($button-icon-size, $button-font-size);
      height: em.convert($button-icon-size, $button-font-size);
      padding: em.convert(3px, $button-font-size);
      background: conic-gradient(from 0 at 50% 50%, transparent 0%, var(--button-color) 100%);
      border-radius: 50%;
      -webkit-mask: radial-gradient(circle at 50% 50%, transparent 50%, white 50%);
      -webkit-mask-composite: destination-out;
      mask: radial-gradient(circle at 50% 50%, transparent 50%, white 50%);
      mask-composite: exclude;
      transform: translate(-50%, -50%) rotate(0deg);
      animation: 0.8s loading linear infinite;
    }

    > * {
      opacity: 0;
      pointer-events: none;
    }
  }

  @keyframes loading {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
      transform: translate(-50%, -50%) rotate(359deg);
    }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-button {
    break-inside: avoid;
    color: #000 !important;
    background-color: #fff !important;
    border-color: #000 !important;

    * {
      color: inherit !important;
      background-color: inherit !important;
    }
  }

  @if $button-has-link {
    .c-button--link {
      background-color: transparent !important;
      border: 0 !important;
    }
  }
}
