/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/spacings";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form {}

.c-form__fields {
  > * {
    margin-top: 0;
    margin-bottom: 0;

    + * {
      margin-top: rem.convert(spacings.size(6));
    }
  }
}
