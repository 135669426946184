/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Push
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/spacings";
@use "../tools/typography" as typo;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-push {
  container-name: push;
  container-type: inline-size;

  position: relative;
  width: 100%;
  overflow: hidden;
  color: colors.get("foreground");
  background-color: colors.get("background");
  border: rem.convert(1px) solid colors.get("primary-200");
  border-radius: 0 rem.convert(20px) rem.convert(20px) 0;

  > * {
    margin: 0;
  }
}

  .c-push__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

    .c-push__body {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: rem.convert(spacings.size(4));
      width: 100%;
      padding: rem.convert(spacings.size(6));
    }

      .c-push__main,
      .c-push__aside {
        > * {
          margin-top: 0;
          margin-bottom: 0;

          + * {
            margin-top: rem.convert(spacings.size(3));
          }
        }
      }

      .c-push__main {}

        .c-push__title {
          @include typo.h4;
          position: static;
        }

        .c-push__richtext {
          .c-push__title + & {
            margin-top: rem.convert(spacings.size(1));
          }
        }

        .c-push__button {
          position: relative;

          .c-push__title + & {
            margin-top: rem.convert(spacings.size(4));
          }
        }

      .c-pus__aside {}

        .c-push__price {}

    .c-push__foot {
      position: relative;
      height: 100%;
      padding: rem.convert(spacings.size(6));
      background-color: colors.get("primary-50");
    }

      .c-push__tag {
        --tag-color: #{ colors.get("foreground-revert") };
        --tag-bg: #{ colors.get("background-revert") };

        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        border-radius: 0 0 rem.convert(20px) 0;

        + * {
          margin-top: rem.convert(spacings.size(6));
        }
      }

/*******************************************************************************
 * Container Queries
*******************************************************************************/

@container push (min-width: 40em) {
  .c-push__container {
    flex-direction: row;
  }

    .c-push__body {
      flex-direction: row;
      gap: rem.convert(spacings.size(6));
      flex: 1 1 auto;
      align-self: center;
      padding: rem.convert(spacings.size(12));
      text-align: left;
    }

      .c-push__main {
        flex: 1 1 auto;
      }

        .c-push__button {
          .c-push__title + & {
            margin-top: rem.convert(spacings.size(6));
          }
        }

      .c-push__aside {
        flex: 0 0 auto;
        align-self: center;
        text-align: center;
      }

    .c-push__foot {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 45%;
      max-width: rem.convert(368px);
      height: auto;

      &:first-child {
        width: 100%;
        max-width: 100%;
        height: 100%;
      }
    }
}


/*******************************************************************************
 * Variants
*******************************************************************************/

.c-push--center {
  @container push (max-width: 47.9375em) {
    .c-push__main {
      text-align: center;
    }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-push {
    break-inside: avoid;
  }

    .c-push__body + .c-push__foot {
      border-left: rem.convert(1px) solid #000;
    }
}
