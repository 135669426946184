/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Figure
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";
@use "sass:math";
@use "sass-rem/rem";
@use "sass-mq/mq";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/spacings";
@use "../tools/global";
@use '../objects/wrapper';
@use '../tools/typography' as typo;
@use "@lunaweb/vega-reactor-scss/src/tools/hide";

/*******************************************************************************
 * Variables
*******************************************************************************/

$figure-font-size: #{ fonts.$base };

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-figure {
  --figure-radius: #{ rem.convert(4px) };

  display: block;
  margin: 0;
  font-size: rem.convert($figure-font-size);
  text-align: center;
}

    .c-figure picture,
    .c-figure__picture {
      display: inline-block;
      overflow: hidden;
      line-height: 0;
      border-radius: var(--figure-radius);
    }

      .c-figure__image {
        border-radius: var(--figure-radius);
      }

    .c-figure__embed {
      position: relative;
      display: block;
      height: 0;
      padding-top: math.div(9, 16) * 100%;
      overflow: hidden;
      border-radius: var(--figure-radius);

      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        overflow: hidden;
        border: 0;
      }
    }

  .c-figure__legend {
    padding: 0 wrapper.$gutter;
    text-align: left;
  }

    .c-figure__text {
      margin-top: rem.convert(spacings.size(3));
    }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq.mq($from: large) {
  .c-figure__legend {
    padding-right: 0;
    padding-left: 0;
  }
}

@include mq.mq($until: medium) {
  .c-figure__text {
    padding: 0 rem.convert(spacings.size(1.5));
  }
}

/*******************************************************************************
 * Variants
*******************************************************************************/

/**
 * Embed
*******************************************************************************/

.c-figure--embed {
  display: block;
  clear: both;

  .c-figure__embed {
    position: relative;
    display: block;
    height: 0;
    padding-top: math.div(9, 16) * 100%;
    overflow: hidden;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      overflow: hidden;
    }
  }
}

/**
 * Radius
*******************************************************************************/

.c-figure--radius {
  --figure-radius: #{ rem.convert(20px) };
}

/**
 * Radius large
*******************************************************************************/

.c-figure--radius-large {
  --figure-radius: 0;

  @include mq.mq($from: large) {
    --figure-radius: #{ rem.convert(20px) };
  }
}

/**
 * Radius responsive
*******************************************************************************/

.c-figure--radius-responsive {
  @include global.fluid-size(12px, 20px, --figure-radius);
}

/**
 * Cover
*******************************************************************************/

.c-figure--cover {
  position: relative;

  .c-figure__embed,
  .c-figure__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/**
 * Copyright
*******************************************************************************/

.c-figure--credit {
  position: relative;
  width: fit-content;
  margin: 0 auto;

  .c-figure__legend {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
    @include global.fluid-size(6px, 16px, padding-left);
    @include global.fluid-size(6px, 16px, padding-right);
    @include global.fluid-size(6px, 16px, padding-bottom);
    @include global.fluid-size(6px, 16px, padding-top);
  }

    .c-figure__copyright {
      display: inline-flex;
      max-width: rem.convert(22px);
      max-height: rem.convert(20px);
      padding: 0;
      overflow: hidden;
      line-height: 1;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: rem.convert(20px);
      transition: all 0.4s ease-in-out;

      &:hover,
      &:focus-within {
        max-width: calc(100% - rem.convert(spacings.size(8)));

        .c-figure__label {
          padding-right: rem.convert(spacings.size(2));
          opacity: 1;
        }
      }

      @include mq.mq($until: small) {
        bottom: calc(100% + rem.convert(spacings.size(4.5)));
        left: rem.convert(spacings.size(1.5));

        &:hover {
          max-width: calc(100% - rem.convert(spacings.size(4)));
        }
      }
    }

      .c-figure__button {
        display: inline-block;
        min-width: rem.convert(22px);
        padding: rem.convert(spacings.size(0.5) spacings.size(1));
        font: inherit;
        text-align: center;
        color: inherit;
        background: transparent;
        border: 0;
        border-radius: 50%;

        &:focus-visible {
          outline-offset: 0;
        }
      }

        .c-figure__label {
          display: inline-flex;
          align-items: center;
          font-size: clamp(8px, 3cqw, 14px);
          white-space: nowrap;
          opacity: 0;
          transition: all 0.4s ease-in-out;
        }

  &.c-figure--legend {
    .c-figure__legend {
      container: figure inline-size;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 0;
    }

      .c-figure__copyright {
        position: absolute;
        bottom: calc(100% + rem.convert(spacings.size(6)));
        @include global.fluid-size(spacings.size(1.5), spacings.size(4), left);
        z-index: 1;
      }
  }

  &.c-figure--showcase {
    @include mq.mq($from: medium, $until: filters) {
      .c-figure__legend {
        text-align: end;
      }
    }
  }

  &.c-figure--cover {
    width: auto;
    margin: 0;
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-figure {
    break-inside: avoid;
  }
}
