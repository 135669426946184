/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Pagination
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";
@use "sass-em/em";
@use "sass-rem/rem";
@use "sass-mq/mq" as *;
@use "../settings/colors";
@use "../settings/fonts";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Variables
*******************************************************************************/

$pagination-font-size: fonts.$small !default;
$pagination-gap: 4px !default;
$pagination-item-size: 40px !default;
$pagination-has-focus-behaviour: true !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-pagination {
  position: relative;
  font-size: rem.convert($pagination-font-size);
  text-align: center;
}

  .c-pagination__navigation {
    overflow: hidden;
  }

    .c-pagination__items {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: em.convert(math.div(-$pagination-gap, 4), $pagination-font-size) em.convert(math.div(-$pagination-gap, 2), $pagination-font-size);
      list-style: none;
    }

      .c-pagination__item {
        display: inline-block;
        padding: em.convert(math.div($pagination-gap, 4), $pagination-font-size) em.convert(math.div($pagination-gap, 2), $pagination-font-size);

        &.c-pagination__item--prev {
          padding-right: em.convert((math.div($pagination-gap, 4) + $pagination-gap), $pagination-font-size);
        }

        &.c-pagination__item--next {
          padding-left: em.convert((math.div($pagination-gap, 4) + $pagination-gap), $pagination-font-size);
        }
      }

      .c-pagination__link {
        --color: inherit;
        --background: transparent;

        display: inline-block;
        min-width: em.convert($pagination-item-size, $pagination-font-size);
        padding:
          em.convert(math.div($pagination-item-size - ($pagination-font-size * 1.5), 2), $pagination-font-size)
          em.convert(($pagination-gap), $pagination-font-size);
        font-weight: 700;
        text-align: center;
        text-decoration: none;
        color: var(--color);
        background-color: var(--background);
        border-radius: em.convert($pagination-item-size, $pagination-font-size);

        &[aria-current="page"] {
          --color: #{ colors.get("foreground-revert") };
          --background: #{ colors.get("primary") };
        }
      }

        a.c-pagination__link,
        button.c-pagination__link {
          --color: #{ colors.get("primary") };

          @include animation.transition(color background-color);

          &:hover {
            --background: #{ colors.get("primary-100") };
          }

          &:active {
            --background: #{ colors.get("primary-200") };

            transition-duration: 0s;
          }
        }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($until: small) {
  .c-pagination__navigation {
    display: block;
  }

    .c-pagination__item--prev,
    .c-pagination__item--next {
      flex-grow: 1;
    }

    .c-pagination__item--prev {
      text-align: start;
    }

    .c-pagination__item--next {
      text-align: end;
    }

    .c-pagination__item--optional {
      display: none;
    }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-pagination {
    break-inside: avoid;
  }
}
