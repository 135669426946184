/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Tag
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";
@use "sass-em/em";
@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";
@use "@lunaweb/vega-reactor-scss/src/tools/hide";
@use "@lunaweb/vega-reactor-scss/src/tools/unit";

/*******************************************************************************
 * Variables
*******************************************************************************/

$tag-font-size: fonts.$small !default;
$tag-line-height: 18px !default;
$tag-padding-inline: 12px !default;
$tag-padding-block: 4px !default;
$tag-gap: 6px !default;
$tag-border-width: 1px !default;
$tag-radius: 20px !default;
$tag-icon-size: 18px !default;

$tag-has-primary: true !default;
$tag-has-secondary: true !default;
$tag-has-ghost: true !default;
$tag-has-half-radius: true !default;
$tag-has-input: true !default;
$tag-has-disabled: false !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-tag {
  --tag-color: #{ colors.get("primary-900") };
  --tag-bg: #{ colors.get("background") };
  --tag-border-color: transparent;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding:
    em.convert(($tag-padding-block - $tag-border-width - math.div($tag-gap, 2)), $tag-font-size)
    em.convert(($tag-padding-inline - $tag-border-width - math.div($tag-gap, 2)), $tag-font-size);
  font: inherit;
  font-size: var(--tag-font-size, #{ rem.convert($tag-font-size) });
  font-weight: fonts.$semibold;
  line-height: math.div($tag-line-height, $tag-font-size);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  color: var(--tag-color, currentcolor);
  background: var(--tag-bg, transparent);
  border: em.convert($tag-border-width, $tag-font-size) solid var(--tag-border-color, var(--tag-bg));
  border-radius: em.convert($tag-radius, $tag-font-size);
  @include animation.transition(background);
}

  .c-tag__icon,
  .c-tag__image {
    flex: 0 0 auto;
    margin:
      em.convert(math.div($tag-gap + $tag-line-height - $tag-icon-size, 2), $tag-icon-size)
      em.convert(math.div($tag-gap, 2), $tag-icon-size);
    font-size: em.convert($tag-icon-size, $tag-font-size);
  }

  .c-tag__icon {
    color: var(--tag-icon-color, var(--tag-color));
    @include animation.transition(color transform);
  }

  .c-tag__image {
    width: 1em;
    height: 1em;
    border-radius: 50%;
  }

  .c-tag__label {
    margin: em.convert(math.div($tag-gap, 2), $tag-font-size);
  }

/*******************************************************************************
 * Interactive
*******************************************************************************/

a.c-tag,
button.c-tag {
  cursor: pointer;

  &:hover {
    --tag-color: #{ colors.get("primary-900") };
    --tag-bg: #{ colors.get("primary-50") };
    --tag-border-color: transparent;
  }

  &:active {
    --tag-color: #{ colors.get("primary-900") };
    --tag-bg: #{ colors.get("primary-100") };
    --tag-border-color: transparent;

    transform: translateY(#{ rem.convert(1px) });
    transition-duration: 0s;

    .c-tag__icon {
      transition-duration: 0s;
    }
  }
}

/*******************************************************************************
 * Variants
*******************************************************************************/

/**
 * Primary
*******************************************************************************/

@if $tag-has-primary {
  .c-tag--primary {
    --tag-color: #{ colors.get("foreground-revert") };
    --tag-bg: #{ colors.get("primary-800") };
    --tag-border-color: transparent;
  }

  a.c-tag--primary,
  button.c-tag--primary {
    &:hover {
      --tag-color: #{ colors.get("foreground-revert") };
      --tag-bg: #{ colors.get("primary-900") };
      --tag-border-color: transparent;
    }

    &:active {
      --tag-color: #{ colors.get("foreground-revert") };
      --tag-bg: #{ colors.get("primary-900") };
      --tag-border-color: transparent;
    }
  }
}

/**
 * Secondary
*******************************************************************************/

@if $tag-has-secondary {
  .c-tag--secondary {
    --tag-color: #{ colors.get("primary-900") };
    --tag-bg: #{ colors.get("primary-50") };
    --tag-border-color: transparent;
  }

  a.c-tag--secondary,
  button.c-tag--secondary {
    &:hover {
      --tag-color: #{ colors.get("primary-900") };
      --tag-bg: #{ colors.get("primary-100") };
      --tag-border-color: transparent;
    }

    &:active {
      --tag-color: #{ colors.get("primary-900") };
      --tag-bg: #{ colors.get("primary-200") };
      --tag-border-color: transparent;
    }
  }
}

/**
 * Ghost
*******************************************************************************/

@if $tag-has-ghost {
  .c-tag--ghost {
    --tag-color: inherit;
    --tag-bg: transparent;
    --tag-border-color: transparent;

    padding: 0;

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > :first-child {
      margin-left: 0;
    }

    > :last-child {
      margin-right: 0;
    }
  }

  a.c-tag--ghost,
  button.c-tag--ghost {
    &:hover {
      --tag-color: inherit;
      --tag-bg: transparent;
      --tag-border-color: transparent;
    }

    &:active {
      --tag-color: inherit;
      --tag-bg: transparent;
      --tag-border-color: transparent;
    }
  }
}

/**
 * Half radius
*******************************************************************************/

@if $tag-has-half-radius {
  .c-tag--half-radius {
    border-radius: 0 em.convert($tag-radius, $tag-font-size) em.convert($tag-radius, $tag-font-size) 0;
  }
}

/**
 * Input
*******************************************************************************/

@if $tag-has-input {
  .c-tag--input {
    --tag-color: #{ colors.get("foreground") };
    --tag-bg: #{ colors.get("background") };
    --tag-border-color: transparent;

    position: relative;

    .c-tag__input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      appearance: none;
      cursor: pointer;
      background: transparent;
      border: 0;
      border-radius: inherit;

      + .c-tag__icon {
        font-size: 0;
        @include animation.transition(font-size);
      }
    }

    &:has(.c-tag__input:checked) {
      --tag-color: #{ colors.get("foreground-revert") };
      --tag-bg: #{ colors.get("primary") };
      --tag-border-color: transparent;

      .c-tag__input + .c-tag__icon {
        font-size: em.convert($tag-icon-size, $tag-font-size);
      }
    }
  }
}

/*******************************************************************************
 * Disabled
*******************************************************************************/

@if $tag-has-disabled {
  .c-tag,
  button.c-tag,
  a.c-tag {
    &[disabled],
    &[aria-disabled],
    &.is-disabled {
      --tag-bg: #fff;
      --tag-color: #4d5562;
      --tag-border-color: #d2d5da;

      pointer-events: none;

      @if $tag-has-primary {
        &.c-tag--primary {
          --tag-bg: #d2d5da;
          --tag-color: #4d5562;
          --tag-border-color: #d2d5da;
        }
      }
    }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-tag {
    break-inside: avoid;
    color: #000 !important;
    background-color: #fff !important;
    border-color: #000 !important;

    * {
      color: inherit !important;
      background-color: inherit !important;
    }
  }

  @if $tag-has-ghost {
    .c-tag--ghost {
      background-color: transparent !important;
      border: 0 !important;
    }
  }
}
