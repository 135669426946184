/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Gallery
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/spacings";
@use "../tools/global";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-gallery {
  position: relative;
}

  .c-gallery__button {
    --button-icon-color: #{ colors.get("secondary") };
    --button-bg: #{ colors.get("background") };

    position: absolute;

    @include global.fluid-size(spacings.size(1.5), spacings.size(5), bottom);
    @include global.fluid-size(spacings.size(1.5), spacings.size(5), right);
  }

  .c-gallery__items {
    display: none;
  }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-gallery--legend {
  .c-gallery__button {
    @include global.fluid-size(spacings.size(10.5), spacings.size(13), bottom);
    @include global.fluid-size(spacings.size(1.5), spacings.size(4), right);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-gallery {
    break-inside: avoid;
  }
}
