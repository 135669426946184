/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Field
 * Type: Component
 * Description: A group of elements which purpose is to get user's input.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Default styles
*******************************************************************************/

/**
 * [1] Reset fieldset styles, read : https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
 */
.c-form-field {
  min-width: 0; /* [1] */
  padding: 0.01em 0 0; /* [1] */
  margin: 0; /* [1] */
  border: 0; /* [1] */

  body:not(:-moz-handler-blocked) & {
    display: table-cell; /* [1] */
  }
}

  /**
   * [1] Reset legend styles, read : https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
   */
  .c-form-field__label {
    display: table; /* [1] */
    padding: 0; /* [1] */
    margin: 0;
    @include animation.transition(color);
  }

  .c-form-field__hint {}

  .c-form-field__feedback {}

  .c-form-field__control {
    display: flex;
    flex-wrap: wrap;
    gap: rem.convert(8px);

    &:not(:first-child),
    + * {
      margin-top: rem.convert(4px);
    }

    > * {
      flex: var(--flex, 999 0 auto);
      max-width: 100%;
    }
  }

/*******************************************************************************
 * Specific
*******************************************************************************/

.c-form-field--subject,
.c-form-field--message,
.c-form-field--gdpr,
.c-form-field--actions {
  grid-column: 1 / -1;
}
