@use "parvus/src/scss/parvus";
@use "../settings/colors";

:root {
  --parvus-overlay-color: #{ colors.get("primary-50") };
  --parvus-overlay-text: #{ colors.get("foreground") };
  --parvus-caption-color: #{ colors.get("foreground") };
  --parvus-button-color: #{ colors.get("primary") };
  --parvus-button-text: #{ colors.get("foreground-revert") };
  --parvus-button-hover-color: #{ colors.get("primary-900") };
  --parvus-button-hover-text: #{ colors.get("foreground-revert") };
  --parvus-error-loading-error-background-color: #{ colors.get("background") };
  --parvus-image-loading-error-color: #{ colors.get("foreground") };
  --parvus-loader-background-color: #{ colors.get("background") };
  --parvus-loader-color: #{ colors.get("primary") };
}

.parvus {
  .parvus__slide img {
    transform-origin: center center;
  }

  .parvus__btn {
    border-radius: 3em;
  }
}
