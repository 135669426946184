/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Navigation
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-em/em";
@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/spacings";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-navigation {
  --gap: #{ rem.convert(spacings.size(2)) };

  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

  .c-navigation__items {
    display: flex;
    flex: 1 0 auto;
    gap: var(--gap);
    padding: 0;
    margin: 0;
    list-style: none;
  }

    .c-navigation__item {}

      .c-navigation__search {
        --button-color: #{ colors.get("foreground") };
        --button-bg: #{ colors.get("background") };
        --button-icon-color: #{ colors.get("primary") };
        --button-border-color: #{ colors.get("neutral-300") };
      }

      .c-navigation__control {
        position: relative;
        display: flex;
        align-items: center;
        gap: rem.convert(spacings.size(1));
        padding: rem.convert(spacings.size(3) spacings.size(2));
        font: inherit;
        appearance: none;
        cursor: pointer;
        color: inherit;
        background-color: transparent;
        border: 0;
        text-decoration-line: none;

        &.c-navigation__control--current {
          font-weight: fonts.$bold;
        }
      }

        .c-navigation__label {}

        .c-navigation__marker {
          @include animation.transition(transform);
        }

      .c-navigation__megamenu {
        position: absolute;
        right: 0;
        left: 0;
        display: none;
        overflow-y: auto;
        overscroll-behavior: contain;
        color: colors.get("foreground");
        background-color: colors.get("background");

        .c-navigation.is-mounted &:not([hidden]) {
          display: block;
        }
      }

  .c-navigation__foot {
    display: none;
  }

    .c-navigation__link {}

      .c-navigation__icon {
        font-size: em.convert(fonts.$base, fonts.$small);
        text-decoration: none;
      }

/*******************************************************************************
 * States
*******************************************************************************/

/**
 * Vertical
*******************************************************************************/

.c-navigation.has-burger {
  --gap: 0;

  .c-navigation__items {
    flex-direction: column;
    padding: rem.convert(spacings.size(6));
  }

  .c-navigation__item {
    + .c-navigation__item {
      border-top: rem.convert(1px) solid colors.get("secondary-200");
    }

    &.c-navigation__item--search {
      order: -1;
      border-top: 0;
    }
  }

    .c-navigation__control {
      justify-content: space-between;
      width: 100%;
      padding: rem.convert(spacings.size(6) 0);

      &.c-navigation__control--current {
        &::after {
          display: none;
        }
      }
    }

    .c-navigation__megamenu {
      top: 0;
      bottom: 0;
      z-index: 1;
      color: colors.get("foreground");
      background-color: colors.get("secondary-50");
      animation: navigation-vertical-megamenu-open animation.$transition-duration ease-in-out;
    }

    .c-navigation__search {
      justify-content: space-between;
      width: 100%;
    }

  .c-navigation__foot {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: spacings.size(8);
    margin: 0;
    overflow: hidden;
    font-size: rem.convert(fonts.$small);
    list-style: none;
    color: colors.get("foreground-revert");
    background-color: colors.get("background-revert");

    .c-navigation__item {
      padding: 0 spacings.size(4);
      border: 0;

      &:last-child {
        border-left: rem.convert(1px) solid colors.get("primary");
      }
    }
  }

      .c-navigation__link {
        display: inline-block;
      }
}

/**
 * Horizontal
*******************************************************************************/

.c-navigation:not(.has-burger) {
  .c-navigation__item--search {
    align-self: center;
  }

    .c-navigation__control {
      &::after {
        content: "";
        position: absolute;
        right: rem.convert(spacings.size(2));
        bottom: 0;
        left: rem.convert(spacings.size(2));
        display: block;
        height: rem.convert(1px);
        background-color: colors.get("secondary-500");
        transform: scaleX(0);
        transform-origin: 0 center;
        @include animation.transition(transform);
      }

      &:hover,
      &[aria-expanded="true"] {
        &::after {
          transform: scaleX(1);
        }
      }
    }

      .c-navigation__marker {
        color: colors.get("primary-600");
        transform: rotate(0.25turn);
      }

      .c-navigation__control[aria-expanded="true"] .c-navigation__marker {
        transform: rotate(-0.25turn);
      }

      a.c-navigation__control .c-navigation__marker {
        display: none;
      }

    .c-navigation__megamenu {
      top: 100%;
      bottom: auto;
      max-height: calc(100vh - var(--header-height) - var(--navigation-height));
      max-height: calc(100vh - var(--header-height) - var(--navigation-height) - var(--vh-offset));
      animation: navigation-horizontal-megamenu-open animation.$transition-duration ease-in-out;
    }
}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes navigation-horizontal-megamenu-open {
  from {
    opacity: 0;
    transform: translateY(#{ rem.convert(spacings.size(-4)) });
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes navigation-vertical-megamenu-open {
  from {
    opacity: 0;
    transform: translateX(25%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
