/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Heading
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/fonts";
@use "../settings/spacings";
@use "../tools/global";

/*******************************************************************************
 * Variables
*******************************************************************************/

$heading-font-size: fonts.$base;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-heading {
  @include global.fluid-size(spacings.size(6), spacings.size(12), padding-top);
  @include global.fluid-size(spacings.size(6), spacings.size(18), padding-bottom);
  font-size: rem.convert($heading-font-size);
}

  .c-heading__wrapper {
    > * {
      margin-top: 0;
      margin-bottom: 0;

      + * {
        @include global.fluid-size(spacings.size(4), spacings.size(6), margin-top);
      }
    }
  }

    .c-heading__breadcrumbs {
      @include global.fluid-size(spacings.size(4), spacings.size(12), margin-bottom);
    }

    .c-heading__icon {
      font-size: rem.convert(60px);
    }

    .c-heading__meta {
      .c-heading__icon + & {
        margin-top: rem.convert(spacings.size(6));
      }
    }

      .c-heading__suptitle {}

      .c-heading__tag {}

    .c-heading__title {
      .c-heading__icon + & {
        margin-top: rem.convert(spacings.size(3));
      }

      .c-heading__icon + .c-heading__meta + & {
        @include global.fluid-size(spacings.size(2), spacings.size(3), margin-top);
      }
    }

    .c-heading__richtext {
      .c-heading__title + & {
        margin-top: rem.convert(spacings.size(6));
      }
    }

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-heading--center {
  text-align: center;

  .c-heading__breadcrumbs {
    --breadcrumbs-align: center;
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-heading {
    break-inside: avoid;
  }
}
