/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Megamenu
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";
@use "sass-rem/rem";
@use "sass-em/em";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/spacings";
@use "../settings/global";
@use "../tools/typography" as typo;
@use "@lunaweb/vega-reactor-scss/src/tools/objects";
@use "@lunaweb/vega-reactor-scss/src/tools/svg";

/*******************************************************************************
 * Variables
*******************************************************************************/

$megamenu-font-size: fonts.$base;
$megamenu-wrapper: 1344px;
$megamenu-head: 472px;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-megamenu {
  position: relative;
  font-size: rem.convert($megamenu-font-size);
}

  .c-megamenu__wrapper {
    @include objects.wrapper($megamenu-wrapper, 0);
  }

    .c-megamenu__head {
      padding: rem.convert(spacings.size(6) spacings.size(8));
      color: colors.get("foreground-revert");
      background-color: colors.get("primary");

      > * {
        margin-top: 0;
        margin-bottom: 0;

        + * {
          margin-top: rem.convert(spacings.size(1));
        }
      }
    }

      .c-megamenu__close {
        margin-bottom: rem.convert(spacings.size(2));
        color: inherit;
      }

      .c-megamenu__title {
        @include typo.h4;
        font-weight: fonts.$bold;
      }

      .c-megamenu__richtext {}

    .c-megamenu__body {
      padding: rem.convert(spacings.size(6) spacings.size(8));
    }

    .c-megamenu__items {
      padding: 0;
      list-style: none;
    }

      .c-megamenu__item {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 1em;
          height: 1em;
          font-size: em.convert(16px, $megamenu-font-size);
          background-image: svg.icon(map.get(global.$icons, "chevron-right"), map.get(colors.$functionals, "secondary"));
          background-repeat: no-repeat;
          background-position: center;
          background-size: 1em;
        }
      }

        .c-megamenu__link {
          display: inline-flex;
          text-decoration-line: none;
        }

          .c-megamenu__label {}

          .c-megamenu__marker {
            margin-top: em.convert(5px, $megamenu-font-size);
          }

/*******************************************************************************
 * States
*******************************************************************************/

/**
 * Horizontal
*******************************************************************************/

.c-megamenu.is-horizontal {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(50% - #{ $megamenu-wrapper } / 2 + #{ spacings.size(1) });
    background-color: colors.get("primary");
  }

  .c-megamenu__wrapper {
    display: flex;
  }

    .c-megamenu__head {
      width: rem.convert($megamenu-head);
      padding: rem.convert(spacings.size(18));
    }

      .c-megamenu__close {
        display: none;
      }

    .c-megamenu__body {
      flex: 1 1 auto;
      padding: rem.convert(spacings.size(18) spacings.size(12));
    }

      .c-megamenu__items {
        padding: 0;
        columns: rem.convert(240px) auto;
        column-gap: rem.convert(spacings.size(12));
        list-style: none;
      }

        .c-megamenu__item {
          padding-left: rem.convert(spacings.size(4));

          &::before {
            top: em.convert(4px, 16px);
            left: 0;
          }

          + .c-megamenu__item {
            margin-top: rem.convert(spacings.size(2));
          }
        }

          .c-megamenu__link {
            flex-direction: row-reverse;
            text-decoration-line: underline;

            &:hover {
              text-decoration-color: transparent;
            }
          }
}

/**
 * Vertical
*******************************************************************************/

.c-megamenu:not(.is-horizontal) {
  .c-megamenu__item {
    padding-right: rem.convert(spacings.size(4));

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      left: auto;
      background-image: svg.icon(map.get(global.$icons, "chevron-right"), map.get(colors.$functionals, "foreground"));
      background-repeat: no-repeat;
      background-position: center;
      background-size: 1em;
      transform: translateY(-50%);
    }

    + .c-megamenu__item {
      border-top: rem.convert(1px) solid colors.get("secondary-200");
    }
  }

    .c-megamenu__link {
      justify-content: space-between;
      width: 100%;
      padding: rem.convert(spacings.size(6) 0);
    }
}
