/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Text
 * Type: Utilities
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/fonts";
@use "../tools/typography" as typo;

/*******************************************************************************
 * Base
*******************************************************************************/

// .u-text-left {
//   text-align: left !important;
// }

.u-text-center {
  text-align: center;
}

// .u-text-right {
//   text-align: right !important;
// }

// .u-text-inherit {
//   text-align: inherit !important;
// }

.u-text-regular {
  font-weight: fonts.$regular;
}

.u-text-small {
  @include typo.text-small;
}

.u-text-normal {
  @include typo.text;
  font-family: fonts.$default;
  font-weight: fonts.$regular;

  @if fonts.$use-vfont {
    font-variation-settings: "wdth" 100;
  }
}

.u-text-large {
  @include typo.text-large;
}

.u-text-semibold {
  font-weight: fonts.$semibold;
}

.u-text-bold {
  font-weight: fonts.$bold;
}

.u-text-expanded {
  font-family: fonts.$primary;
  line-height: 1.2;

  @if fonts.$use-vfont {
    font-variation-settings: "wdth" 125;
  }
}

.u-text-tag {
  font-family: fonts.$primary;
  font-size: rem.convert(fonts.$small);
  font-weight: fonts.$bold;
  line-height: 1.2;
  text-transform: uppercase;

  @if fonts.$use-vfont {
    font-variation-settings: "wdth" 125;
  }
}

.u-text-unlist {
  padding-left: 0;
  list-style: none;
}
