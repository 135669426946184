/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Notice
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/spacings";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Variables
*******************************************************************************/

$notice-font-size: fonts.$base;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-notice {
  container-name: notice;
  container-type: inline-size;

  width: 100%;
  font-size: rem.convert($notice-font-size);
  color: colors.get("foreground-revert");
  background-color: colors.get("primary");
}

  .c-notice__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: spacings.size(2);
    padding: rem.convert(spacings.size(2) spacings.size(5));
    text-align: center;

    > * {
      margin: 0;
    }
  }

    .c-notice__title {
      flex: 1 1 auto;
    }

    .c-notice__close {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      padding: 0 spacings.size(1);
      cursor: pointer;
      color: inherit;
      background-color: transparent;
      border: 0;
    }

      .c-notice__label {
        text-decoration-line: underline;
        text-decoration-color: currentcolor;
        @include animation.transition(text-decoration-color);

        .c-notice__close:hover & {
          text-decoration-color: transparent;
        }
      }

      .c-notice__icon {}

/*******************************************************************************
 * States
*******************************************************************************/

.c-notice:not(.is-mounted) {
  display: none;
}

/*******************************************************************************
 * Container Queries
*******************************************************************************/

@container notice (min-width: 30em) {
  .c-notice__container {
    flex-direction: row;
    padding-block: rem.convert(spacings.size(3));
    text-align: inherit;
  }
}
