/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Price
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "sass-em/em";
@use "../settings/fonts";
@use "../settings/spacings";

/*******************************************************************************
 * Variables
*******************************************************************************/

$price-font-size: fonts.$large;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-price {
  font-size: rem.convert($price-font-size);
}

  .c-price__main,
  .c-price__suffix {
    font-weight: fonts.$bold;
  }

  .c-price__prefix {
    display: block;
    margin-bottom: rem.convert(spacings.size(1));
    font-size: em.convert(fonts.$small, $price-font-size);
  }

  .c-price__main {}

  .c-price__suffix {}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-price {
    break-inside: avoid;
  }
}
