/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Burger
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";
@use "sass-em/em";
@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/spacings";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/**
 * Variables
*******************************************************************************/

$burger-font-size: fonts.$base;
$burger-line-width: 20px;
$burger-line-height: 2px;
$burger-line-gap: 3px;
$burger-padding: 6px;

 /**
 * Styles
*******************************************************************************/

.c-burger {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding: em.convert($burger-padding, $burger-font-size);
  font: inherit;
  font-size: rem.convert($burger-font-size);
  font-weight: fonts.$regular;
  appearance: none;
  cursor: pointer;
  color: inherit;
  background-color: transparent;
  border: 0;
  border-radius: em.convert(5px, $burger-font-size);
}

  .c-burger__label {
    margin-right: em.convert($burger-line-gap, $burger-font-size);
  }

  .c-burger__lines {
    position: relative;
    display: block;
    width: em.convert($burger-line-width + $burger-padding * 2, $burger-font-size);
    height: em.convert($burger-line-width + $burger-padding * 2, $burger-font-size);
    padding: em.convert($burger-padding, $burger-font-size);
    line-height: 0;
    color: colors.get("primary-500");
  }

    .c-burger__line {
      position: absolute;
      top: 50%;
      left: em.convert($burger-padding, $burger-font-size);
      width: em.convert($burger-line-width, $burger-font-size);
      height: em.convert($burger-line-height, $burger-font-size);
      margin-top: em.convert(math.div(-$burger-line-height, 2), $burger-font-size);
      background-color: currentcolor;
      border-radius: em.convert($burger-line-height, $burger-font-size);
      transform: translate(0, 0);
      transform-origin: left center;
      @include animation.transition(width color transform opacity);

      &:first-child,
      &:last-child {
        transform-origin: center center;
      }

      &:first-child {
        transform: translate(#{ em.convert(1px, $burger-font-size)}, #{ em.convert(-($burger-line-height + $burger-line-gap), $burger-font-size) });
      }

      &:last-child {
        transform: translate(#{ em.convert(-1px, $burger-font-size)}, #{ em.convert(($burger-line-height + $burger-line-gap), $burger-font-size) });
      }

      @supports (clip-path: url(#line-2)) {
        border-radius: 0;
        clip-path: url(#line-2);

        &:first-child {
          height: em.convert($burger-line-height * 0.75, $burger-font-size);
          clip-path: url(#line-1);
        }

        &:last-child {
          clip-path: url(#line-3);
        }
      }
    }

/*******************************************************************************
 * States
*******************************************************************************/

.c-burger[aria-expanded="true"],
.c-burger.is-expanded {
  @include animation.transition(transform);

  .c-burger__line {
    left: em.convert($burger-padding - 1px, $burger-font-size);
    color: colors.get("secondary-500");
    opacity: 0;
    transform: translateX(#{ em.convert(1px, $burger-font-size)}) scaleX(0);

    &:first-child,
    &:last-child {
      opacity: 1;
    }

    &:first-child {
      transform:
        rotate(45deg)
        translateX(#{em.convert(1px, $burger-font-size)})
        translateY(#{em.convert(-1px, $burger-font-size)})
        scaleX(1.2);
    }

    &:last-child {
      transform:
        rotate(-45deg)
        translateX(#{em.convert(1px, $burger-font-size)})
        translateY(#{em.convert(1px, $burger-font-size)})
        scaleX(1.2);
    }
  }
}

/**
 * Print
*******************************************************************************/

@media print {
  .c-burger {
    break-inside: avoid;
  }

    .c-burger__line {
      background-color: currentcolor !important;
    }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-burger {
    break-inside: avoid;
  }
}
