/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Headings
 * Type: Utilities
 * Description:
 *   Redefine all of our basic heading styles against utility classes so as to
 *   allow for double stranded heading hierarchy, e.g. we semantically need an
 *   H2, but we want it to be sized like an H1:
 *
 *     <h2 class="u-h1"></h2>
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "../tools/typography" as typo;

/*******************************************************************************
 * Base
*******************************************************************************/

// .u-h1 {
//   @include typo.h1;
// }

// .u-h2 {
//   @include typo.h2;
// }

.u-h3 {
  @include typo.h3;
}

.u-h4 {
  @include typo.h4;
}

.u-h5 {
  @include typo.h5;
}

// .u-h6 {
//   @include typo.h6;
// }
