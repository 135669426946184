/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Fieldset
 * Type: Component
 * Description:
 *  An element used to group set of options under a same label or sectioning
 *  a form into several group of related fields.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";

/*******************************************************************************
 * Default styles
*******************************************************************************/

/**
 * [1] Reset fieldset styles, read : https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
 */
 .c-form-fieldset {
  min-width: 0; /* [1] */
  padding: 0.01em 0 0; /* [1] */
  margin: 0; /* [1] */
  border: 0; /* [1] */

  body:not(:-moz-handler-blocked) & {
    display: table-cell; /* [1] */
  }
}

  /**
   * [1] Reset legend styles, read : https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
   */
  .c-form-fieldset__label {
    display: table; /* [1] */
    padding: 0; /* [1] */
  }

  .c-form-fieldset__note {}

  .c-form-fieldset__error {
    margin-top: rem.convert(8px);
  }

  .c-form-fieldset__content {
    margin-top: rem.convert(8px);
  }
