/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Card
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/spacings";
@use "../tools/typography" as typo;
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Variables
*******************************************************************************/

$card-font-size: fonts.$base;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-card {
  --card-color: #{ colors.get("foreground") };
  --card-background: #{ colors.get("background") };

  container-name: card;
  container-type: inline-size;

  position: relative;
  font-size: rem.convert($card-font-size);
  color: var(--card-color);
  background-color: var(--card-background);
  border: rem.convert(1px) solid transparent;
  border-right: 0;
  border-left: 0;
}

  .c-card__container {
    padding: clamp(rem.convert(spacings.size(4)), 6cqw, rem.convert(spacings.size(8)));

    > * {
      margin-top: 0;
      margin-bottom: 0;

      + * {
        margin-top: rem.convert(spacings.size(3));
      }
    }
  }

  .c-card__head {
    position: relative;
    overflow: hidden;
  }

    .c-card__image {
      width: 100%;
    }

  .c-card__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: rem.convert(spacings.size(2));
  }

    .c-card__tag {}

  .c-card__suptitle {
    display: block;
    text-align: left;
    color: colors.get("primary");
  }

  .c-card__title {
    @include typo.h4;
    position: static;
    text-wrap: balance;

    .c-card__suptitle + & {
      margin-top: rem.convert(spacings.size(1));
    }
  }

  .c-card__metas {
    overflow: hidden;
    font-size: rem.convert(fonts.$small);

    .c-card__title + & {
      margin-top: rem.convert(spacings.size(1));
    }
  }

    .c-card__items {
      margin: 0 rem.convert(spacings.size(-3));
    }

      .c-card__meta {
        padding: 0 rem.convert(spacings.size(3));
        border-left: rem.convert(1px) solid colors.get("neutral-300");

        &.c-card__meta--emphasis {
          font-weight: fonts.$semibold;
        }
      }

  .c-card__richtext {
    display: -webkit-box;
    max-height: 4 * 1.5em;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

    .c-card__marker ~ & {
      margin-top: rem.convert(spacings.size(6));
    }
  }

  .c-card__price {}

  .c-card__button {
    position: relative;
  }

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-card--interactive {
  @include animation.transition(border-color);

  .c-card__image {
    transform: scale(1);
    @include animation.transition(transform);
  }

  &:hover {
    border-color: colors.get("primary-200");

    .c-card__image {
      transform: scale(1.1);
    }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-card {
    break-inside: avoid;
    color: #000 !important;
    background-color: #fff !important;
  }
}
