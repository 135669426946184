/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Header
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-em/em";
@use "sass-rem/rem";
@use "sass-mq/mq";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/spacings";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";
@use "@lunaweb/vega-reactor-scss/src/tools/objects";

/*******************************************************************************
 * Variables
*******************************************************************************/

$header-font-size: fonts.$base;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-header {
  font-size: rem.convert($header-font-size);
  color: colors.get("foreground");
  background-color: colors.get("background");
}

  .c-header__top {
    display: flex;
    justify-content: center;
    padding: spacings.size(3) spacings.size(2);
    font-size: rem.convert(fonts.$small);
    color: colors.get("foreground-revert");
    background-color: colors.get("background-revert");
  }

    .c-header__items {
      @include objects.wrapper(1504px);
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: 0;
      list-style: none;
    }

      .c-header__item {
        padding: 0 spacings.size(4);

        &:last-child {
          border-left: rem.convert(1px) solid colors.get("primary");
        }
      }

        .c-header__link {
          display: inline-block;
        }

          .c-header__icon {
            font-size: em.convert(fonts.$base, fonts.$small);
            text-decoration: none;
          }

  .c-header__main {
    padding: rem.convert(spacings.size(2) spacings.size(5));
  }

    .c-header__wrapper {
      @include objects.wrapper(1504px);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

      .c-header__logo {
        font-size: rem.convert(40px);
        color: colors.get("primary-500");
      }

      .c-header__nav {}

        .c-header__burger {
          font-size: rem.convert(20px);
        }

        .c-header__navigation {}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq.mq($until: navigation) {
  .c-header__top {
    display: none;
  }

    .c-header__wrapper {
      --gutter: 0;
    }

      .c-header__navigation {
        position: absolute;
        top: var(--header-height);
        right: 0;
        left: 0;
        height: calc(100vh - var(--header-height) - var(--vh-offset));
        overflow-y: auto;
        overscroll-behavior: contain;
        color: colors.get("foreground");
        background-color: colors.get("secondary-50");
        animation: header-navigation-open animation.$transition-duration ease-in-out;

        .no-js &,
        .js .c-header:not(.is-mounted) &,
        &[hidden] {
          display: none;
        }
      }
}

@include mq.mq($from: navigation) {
  .c-header__main {
    padding-top: rem.convert(spacings.size(4));
    padding-bottom: rem.convert(spacings.size(4));
  }

    .c-header__burger {
      display: none;
    }
}

@include mq.mq($from: huge) {
  .c-header__logo {
    font-size: rem.convert(56px);
  }

  .c-header__navigation {
    --gap: #{ rem.convert(spacings.size(8)) };
  }
}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes header-navigation-open {
  from {
    opacity: 0;
    transform: translateX(-25%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
