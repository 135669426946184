/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Placeholder
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/spacings";
@use "../tools/global";
@use "../tools/typography" as typo;
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Variables
*******************************************************************************/

$placeholder-font-size: #{ fonts.$base };

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-placeholder {
  --placeholder-radius: #{ rem.convert(4px) };

  display: block;
  margin: 0;
  text-align: center;

  container-name: placeholder;
  position: relative;
  border-radius: var(--placeholder-radius);
  overflow: hidden;
  max-width: 100%;
  max-height: rem.convert(300px);
  padding-bottom: 60%;
  background-color: colors.get('primary-50');
}

  .c-placeholder__gimmick {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: clamp(rem.convert(30px), 15cqw, rem.convert(100px));
    color: colors.get("primary-500");
    transform: translate(-50%, -50%);
  }


/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-placeholder {
  &.c-placeholder--avatar {
    width: 1em;
    height: 1em;
    padding-bottom: 0;
    border-radius: (100px);
    outline: solid rem.convert(2px) colors.get('success-500');

    .c-placeholder__gimmick {
      font-size: clamp(rem.convert(30px), 15cqw, rem.convert(30px));
    }
  }
}

.c-placeholder {
  &.c-placeholder--showcase {
    width: 100%;
    padding-bottom: 50%;

    .c-placeholder__gimmick {
      font-size: clamp(rem.convert(30px), 20cqw, rem.convert(100px));
    }
  }
}

.c-placeholder {
  &.c-placeholder--triptych {
    @include global.fluid-size(spacings.size(1), spacings.size(6), --space);
    width: calc(33% - var(--space));
    max-height: rem.convert(368px);
    padding-bottom: initial;
    border-radius: rem.convert(20px);

    .c-placeholder__gimmick {
      font-size: clamp(rem.convert(30px), 20cqw, rem.convert(90px));
    }
  }
}

.c-placeholder {
  &.c-placeholder--album {
    max-height: initial;
    border-radius: 0;
  }
}

.c-placeholder {
  &.c-placeholder--card {
    padding-bottom: 57.5%;
    border-radius: 0;
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/
