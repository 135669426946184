/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: VCard
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/fonts";
@use "../settings/spacings";

/*******************************************************************************
 * Variables
*******************************************************************************/

$vcard-font-size: fonts.$small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-vcard {
  --vcard-avatar-size: #{ rem.convert(100px) };

  position: relative;
  font-size: rem.convert($vcard-font-size);
}

  .c-vcard__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: rem.convert(spacings.size(3));
    text-align: center;
  }

    .c-vcard__avatar {
      font-size: var(--vcard-avatar-size);
    }

    .c-vcard__placeholder {
      font-size: var(--vcard-avatar-size);
    }

    .c-vcard__body {
      > * {
        margin-top: 0;
        margin-bottom: 0;

        + * {
          margin-top: rem.convert(spacings.size(2));
        }
      }
    }

      .c-vcard__title {
        position: static;
        font-size: rem.convert(fonts.$large);
        font-weight: fonts.$bold;
      }

      .c-vcard__subtitle {
        .c-vcard__title + & {
          margin-top: rem.convert(spacings.size(1));
        }
      }

      .c-vcard__phone {
        position: relative;
        font-weight: fonts.$bold;
        text-decoration-line: none;

        &:hover {
          text-decoration-line: underline;
        }
      }

      .c-vcard__actions {
        position: relative;
        padding: 0;

        > * {
          margin-top: 0;
          margin-bottom: 0;

          + * {
            margin-top: rem.convert(spacings.size(2));
          }
        }
      }

        .c-vcard__action {
          width: fit-content;
          margin-right: auto;
          margin-left: auto;
        }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-vcard--landscape {
  container-name: vcard;
  container-type: inline-size;

  @container vcard (min-width: 20em) {
    .c-vcard__container {
      flex-direction: row;
      text-align: left;
    }

      .c-vcard__avatar {
        flex: 0 0 auto;
        align-self: flex-start;
      }

      .c-vcard__body {
        flex: 1 1 auto;
      }

        .c-vcard__action {
          width: 100%;
          margin-right: 0;
          margin-left: 0;
        }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-vcard {
    break-inside: avoid;
  }
}
