/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Filters
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-em/em";
@use "sass-rem/rem";
@use "sass-mq/mq";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/spacings";
@use "../tools/typography" as typo;
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Variables
*******************************************************************************/

$filters-font-size: fonts.$base;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-filters {
  padding: rem.convert(spacings.size(4));
  font-size: rem.convert($filters-font-size);
  color: colors.get("foreground");
  background-color: colors.get("primary-50");
}

  .c-filters__head,
  .c-filters__body {
    padding: rem.convert(spacings.size(4));
  }

  .c-filters__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: rem.convert(spacings.size(2));

    > * {
      margin: 0;
    }
  }

    .c-filters__title {
      @include typo.text;
      font-weight: fonts.$bold;
    }

    .c-filters__control {
      position: relative;
      padding-right: em.convert(24px, 14px);
      font: inherit;
      font-size: em.convert(14px, $filters-font-size);
      font-weight: fonts.$semibold;
      text-decoration-line: underline;
      cursor: pointer;
      color: colors.get("primary");
      background-color: transparent;
      border: 0;
    }

      .c-filters__marker {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: em.convert(18px, $filters-font-size);
        height: em.convert(18px, $filters-font-size);
        transform: translateY(-50%);
        @include animation.transition(transform);

        &::before,
        &::after {
          --rotate: 0;

          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: em.convert(2px, $filters-font-size);
          background-color: colors.get("secondary");
          border-radius: em.convert(2px, $filters-font-size);
          @include animation.transition(transform);
          transform: translate(-50%, -50%) rotate(var(--rotate));

          @supports (clip-path: url(#line-1)) {
            border-radius: 0;
            clip-path: url(#line-1);
          }
        }

        &::after {
          --rotate: 0.25turn;

          @supports (clip-path: url(#line-2)) {
            clip-path: url(#line-2);
          }
        }

        .c-filters__control:hover & {
          transform: translateY(-50%) rotate(0.5turn);
        }

        .c-filters__control[aria-expanded="true"] &::after {
          --rotate: 0.5turn;
        }
      }

  .c-filters__body {}

    .c-filters__form {
      > * {
        margin-top: 0;
        margin-bottom: 0;

        + * {
          margin-top: rem.convert(spacings.size(6));
        }
      }
    }

      .c-filters__actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: rem.convert(spacings.size(6));
      }

/*******************************************************************************
 * States
*******************************************************************************/

.c-filters:not(.is-mounted) {
  border-radius: 0 rem.convert(20px) rem.convert(20px) 0;

  .c-filters__control {
    display: none;
  }
}

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-filters--horizontal {
  container-name: filters;
  container-type: inline-size;

  display: flex;
  flex-wrap: wrap;

  .c-filters__head {
    flex: 1 0 auto;
  }

  .c-filters__body {
    flex: 1 1 90%;
  }

  .c-filters__form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: rem.convert(spacings.size(6));

    > * {
      flex: 1 1 auto;
    }
  }

    .c-filters__actions {
      flex: 0 1 auto;
      flex-wrap: wrap;
      margin-top: 0;
    }
}

/*******************************************************************************
 * Container Queries
*******************************************************************************/

@container filters (min-width: 48em) {
  .c-filters--horizontal {
    .c-filters__form {
      flex-wrap: nowrap;
    }

      .c-filters__actions {
        flex-shrink: 0;
        flex-direction: column;
      }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-filters {
    break-inside: avoid;
    color: #000 !important;
    background: #fff !important;
    border: rem.convert(1px) solid #000 !important;
  }
}
