/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Label
 * Type: Component
 * Description:
 *  A label to paired with an input control to indicates what we want from the
 *  user.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../../settings/fonts";
@use "../../settings/colors";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-label {
  font-size: rem.convert(fonts.$small);
  font-weight: fonts.$semibold;
}

  .c-form-label__indic {
    font-size: rem.convert(fonts.$small);
    font-style: italic;
    font-weight: fonts.$regular;
    color: colors.get("neutral-500");
  }

.c-form-label--heading {
  font-size: rem.convert(22px);
  font-weight: fonts.$bold;
}
