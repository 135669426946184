/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Cluster
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/spacings";
@use "../tools/global";

/*******************************************************************************
 * Variables
*******************************************************************************/

$cluster-gutter: spacings.$regular;
$cluster-has-large: true !default;
$cluster-has-reverse: false !default;
$cluster-has-center: true !default;
$cluster-has-end: false !default;
$cluster-has-middle: false !default;
$cluster-has-bottom: false !default;
$cluster-has-fluid: false !default;
$cluster-has-nowrap: true !default;
$cluster-has-stretch: false !default;
$cluster-has-grow: false !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-cluster {
  --space: #{ rem.convert($cluster-gutter) };

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--space);
  padding: 0;
  margin: 0;
  list-style: none;
}

    .o-cluster__item {
      margin: 0;

      @if $cluster-has-fluid {
        &.o-cluster__item--fluid {
            flex-grow: 1;
        }
      }
    }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

@if $cluster-has-large {
  .o-cluster--large {
    @include global.fluid-size(spacings.size(1), spacings.size(6), --space);
  }
}

@if $cluster-has-reverse {
  .o-cluster--reverse {
      flex-direction: row-reverse;
      justify-content: flex-end;
  }
}

@if $cluster-has-end {
  .o-cluster--end {
      justify-content: flex-end;
  }
}

@if $cluster-has-center {
  .o-cluster--center {
    justify-content: center;
  }
}

@if $cluster-has-middle {
  .o-cluster--middle {
    align-items: center;
  }
}

@if $cluster-has-bottom {
  .o-cluster--bottom {
    align-items: flex-end;
  }
}

@if $cluster-has-nowrap {
  .o-cluster--nowrap {
    flex-wrap: nowrap;
  }
}

@if $cluster-has-stretch {
  .o-cluster--stretch {
    > .o-cluster__item {
      > * {
        height: 100%;
      }
    }
  }
}

@if $cluster-has-grow {
  .o-cluster--grow {
    > .o-cluster__item {
      flex-grow: 1;
    }
  }
}

.o-cluster__item--middle {
  display: flex;
  align-items: center;
}