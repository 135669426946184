/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Fonts
 * Type: Tool
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";
@use "sass-rem/rem";
@use "../settings/fonts";
@use "../tools/global";

/*******************************************************************************
* Default styles
*******************************************************************************/

// Titile

// Generate overflowing link with pseudo-element
@mixin title($root) {
  .c-#{$root}__mainlink {
    font-weight: inherit;
    text-decoration-color: transparent;
    color: inherit;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:hover {
      text-decoration-line: underline;
      text-decoration-color: currentcolor;
      color: inherit;
    }
  }
}

// Headings

@mixin h0 {
  @include global.fluid-size(30px, 56px);
  font-family: fonts.$primary;
  line-height: 1.2;

  @if fonts.$use-vfont {
    font-variation-settings: "wdth" 125;
  }
}

@mixin h1 {
  @include global.fluid-size(26px, 44px);
  font-family: fonts.$primary;
  line-height: 1.2;

  @if fonts.$use-vfont {
    font-variation-settings: "wdth" 125;
  }
}

@mixin h2 {
  @include global.fluid-size(22px, 36px);
  font-family: fonts.$primary;
  line-height: 1.2;

  @if fonts.$use-vfont {
    font-variation-settings: "wdth" 125;
  }
}

@mixin h3 {
  @include global.fluid-size(18px, 28px);
  font-family: fonts.$primary;
  line-height: 1.2;

  @if fonts.$use-vfont {
    font-variation-settings: "wdth" 125;
  }
}

@mixin h4 {
  @include global.fluid-size(16px, 22px);
  font-family: fonts.$primary;
  line-height: 1.2;

  @if fonts.$use-vfont {
    font-variation-settings: "wdth" 125;
  }
}

@mixin h5 {
  @include global.fluid-size(15px, 18px);
  font-family: fonts.$primary;
  line-height: 1.2;

  @if fonts.$use-vfont {
    font-variation-settings: "wdth" 125;
  }
}

@mixin h6 {
  font-family: fonts.$primary;
  font-size: rem.convert(fonts.$small);
  line-height: 1.2;

  @if fonts.$use-vfont {
    font-variation-settings: "wdth" 125;
  }
}

@mixin text {
  font-size: rem.convert(fonts.$base);
  line-height: inherit;
}

@mixin text-small {
  font-size: rem.convert(fonts.$small);
  line-height: inherit;
}

@mixin text-large {
  @include global.fluid-size(fonts.$base, 20px);
  line-height: inherit;
}
