/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Key
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/spacings";
@use "../tools/global";
@use "../tools/typography" as typo;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-key {
  display: flex;
  flex-direction: column;
  @include global.fluid-size(spacings.size(2), spacings.size(6), gap);
  margin: 0;
  font-size: rem.convert(20px);
}

  .c-key__title {
    @include global.fluid-size(48px, 56px);
    font-family: fonts.$primary;
    font-weight: fonts.$bold;
    line-height: 1.2;
    color: colors.get("primary-800");

    @if fonts.$use-vfont {
      font-variation-settings: "wdth" 125;
    }
  }

  .c-key__subtitle {}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-key {
    break-inside: avoid;
  }
}
