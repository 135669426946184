/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Input
 * Type: Component
 * Description: A simple text input.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";
@use "sass-rem/rem";
@use "../../settings/colors";
@use "../../settings/spacings";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";
@use "@lunaweb/vega-reactor-scss/src/tools/svg";

/**
 * Base
*******************************************************************************/

$base-height: 40px;
$padding-top: 8px;
$padding-right: 16px;
$padding-bottom: 8px;
$padding-left: 16px;
$border-radius: 20px;
$with-action-padding-right: 38px;
$action-offset-top: 11px;
$action-offset-right: 12px;
$action-icon-size: 18px;
$select-icon: "<svg viewBox=\"0 0 18 18\" xmlns=\"http://www.w3.org/2000/svg\">\
  <path d=\"M5.33582 6.22119L8.77832 9.65619L12.2208 6.22119L13.2783 7.27869L8.77832 11.7787L4.27832 7.27869L5.33582 6.22119Z\" fill=\"%color%\" />\
</svg>";

/**
 * Alt
*******************************************************************************/

$alt-enable: false;
$alt-base-height: 56px;
$alt-padding-top: 26px;
$alt-padding-right: 12px;
$alt-padding-bottom: 5px;
$alt-padding-left: 12px;
$alt-border-radius: 4px 4px 0 0;
$alt-with-action-padding-right: 38px;
$alt-action-offset-top: 19px;
$alt-action-offset-right: 12px;

/**
 * With Action
*******************************************************************************/

$with-action-enable: false;

/**
 * Mixins
*******************************************************************************/

/**
 * Attempt to fix label which overlap input's content in certain conditions like
 * use with a textearea or a mutliple select.
 * You might need to tweek some CSS (focus and error styles management) in
 * addition to make this completely working if there is some input's background
 * transitions to manage.
 * The best solution is to have a plain background that never change to
 * accomodate this fix simply.
 */
// @mixin input-alt-label-safe-area {
//   position: relative;

//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     right: 0;
//     left: 0;
//     height: var(--form-input-alt-label-safe-area);
//     background: var(--form-input-background);
//     border-radius: var(--form-input-border-radius);
//     @include animation.transition(background);
//   }
// }

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-input {
  --form-input-height: #{ rem.convert($base-height) };
  --form-input-padding-top: #{ rem.convert($padding-top) };
  --form-input-padding-right: #{ rem.convert($padding-right) };
  --form-input-padding-bottom: #{ rem.convert($padding-bottom) };
  --form-input-padding-left: #{ rem.convert($padding-left) };
  --form-input-action-offset-top: #{ rem.convert($action-offset-top) };
  --form-input-action-offset-right: #{ rem.convert($action-offset-right) };
  --form-input-border-size: #{ rem.convert(1px) };
  --form-input-border-color: #{ colors.get("neutral-500") };
  --form-input-background: #{ colors.get("background") };

  position: relative;
}

@if $alt-enable {
  .c-form-input--alt {
    --form-input-height: #{ rem.convert($alt-base-height) };
    --form-input-padding-top: #{ rem.convert($alt-padding-top) };
    --form-input-padding-right: #{ rem.convert($alt-padding-right) };
    --form-input-padding-bottom: #{ rem.convert($alt-padding-bottom) };
    --form-input-padding-left: #{ rem.convert($alt-padding-left) };
    --form-input-border-radius: #{ rem.convert($alt-border-radius) };
    /* stylelint-disable-next-line length-zero-no-unit */
    --form-input-border-size: 0px; // Need to have an unit to be used in calc()
    --form-input-action-offset-top: #{ rem.convert($alt-action-offset-top) };
    --form-input-action-offset-right: #{ rem.convert($alt-action-offset-right) };
    --form-input-background: #d5ddff;
  }
}

  .c-form-input__control {
    width: 100%;
    height: var(--form-input-height);
    padding:
      calc(var(--form-input-padding-top) - var(--form-input-border-size))
      calc(var(--form-input-padding-right) - var(--form-input-border-size))
      calc(var(--form-input-padding-bottom) - var(--form-input-border-size))
      calc(var(--form-input-padding-left) - var(--form-input-border-size));
    font: inherit;
    text-align: inherit;
    appearance: none;
    color: inherit;
    background: var(--form-input-background);
    border: var(--form-input-border-size, 0) solid var(--form-input-border-color);
    border-radius: var(--form-input-border-radius, $border-radius);
    @include animation.transition(background border padding);

    &::placeholder {
      color: colors.get("neutral-500");
    }

    &[disabled] {
      cursor: not-allowed;
      background-color: colors.get("neutral-200");
      border: transparent;
    }

    &[type="file"] {
      // Set height auto, because the file input is too hard to style consistently for different browsers.
      height: auto;
    }

    @if $alt-enable {
      .c-form-input--alt & {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        &:focus {
          --form-input-background: #abbbff;
        }
      }
    }
  }

  .c-form-input__menu {
    position: absolute;
    top: calc(100% + #{ spacings.size(1) });
    right: 0;
    left: 0;
    z-index: 2;
    // overflow: hidden;
    color: colors.get("foreground");
    background-color: colors.get("background");
    border: var(--form-input-border-size, 0) solid var(--form-input-border-color);
    border-radius: rem.convert(8px);
  }

    .c-form-input__list {
      max-height: min(80vh, rem.convert(400px));
      padding: rem.convert(spacings.size(3));
      overflow-y: auto;
    }

/**
 * Textarea
 ******************************************************************************/

.c-form-input--textarea {
  --form-input-height: 120px;

  // @include input-alt-label-safe-area;

  @if $alt-enable {
    &.c-form-input--alt {
      --form-input-height: 148px;
    }
  }
}

/**
 * Select
 ******************************************************************************/

.c-form-input--select,
.c-form-input--selectmultiple {
  --form-input-padding-right: #{$with-action-padding-right};

  .c-form-input__control:not([multiple]) {
    background-image: svg.icon($select-icon, map.get(colors.$descriptives, "primary-500"));
    background-repeat: no-repeat;
    background-position: right var(--form-input-action-offset-right) top 50%;
    background-size: $action-icon-size $action-icon-size;
  }

  .c-form-input__control[multiple] {
    --form-input-height: 120px;

    // @include input-alt-label-safe-area;
  }
}

.c-form-input--selectmultiple {
  .c-form-input__control {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/*******************************************************************************
 * With action
*******************************************************************************/

@if $with-action-enable {
  .c-form-input--with-action {
    --form-input-padding-right: #{$with-action-padding-right};
  }

    .c-form-input__action {
      position: absolute;
      top: var(--form-input-action-offset-top);
      right: var(--form-input-action-offset-right);
      display: flex;
      align-items: center;
      justify-content: center;
      width: $action-icon-size;
      height: $action-icon-size;
      padding: 0;
      font-size: $action-icon-size;
      cursor: pointer;
      background: transparent;
      border: 0;
    }
}

/*******************************************************************************
 * Feedback styles
*******************************************************************************/

.c-form-input--error {
  .c-form-input__control:not(:focus) {
    --form-input-background: #{ colors.get("error-50") };
    --form-input-border-color: #{ colors.get("error") };
  }

  &:not(.c-form-input--alt) .c-form-input__control:not(:focus) {
    --form-input-border-color: #{ colors.get("error") };
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-form-input--select {
    .c-form-input__control:not([multiple]) {
      background-image: svg.icon($select-icon, #000) !important;
      background-repeat: no-repeat !important;
      background-position: right var(--form-input-action-offset-right) top 50% !important;
      background-size: $action-icon-size $action-icon-size !important;
    }
  }
}
