/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Testimony
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/spacings";
@use "../tools/typography" as typo;

/*******************************************************************************
 * Variables
*******************************************************************************/

$testimony-font-size: fonts.$base;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-testimony {
  container-name: card;
  container-type: inline-size;

  margin: 0;
  font-size: rem.convert($testimony-font-size);
  text-align: center;

  > * {
    margin-top: 0;
    margin-bottom: 0;

    + * {
      margin-top: clamp(spacings.size(4), 4cqw, spacings.size(6));
    }
  }
}

  .c-testimony__icon {
    font-size: clamp(rem.convert(48px), 12cqw, rem.convert(72px));
    color: colors.get("secondary");
  }

  .c-testimony__cite {
    @include typo.h3;
    font-weight: fonts.$bold;
  }

  .c-testimony__footer {}

    .c-testimony__vcard {
      --vcard-avatar-size: #{ rem.convert(60px) };

      max-width: rem.convert(280px);
      margin: 0 auto;
    }

    .c-testimony__credit {
      margin: rem.convert(spacings.size(1)) auto 0 auto;
      font-size: rem.convert(fonts.$small);
      color: colors.get("neutral-500");
    }

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-testimony--condensed {
  text-align: left;

  .c-testimony__cite {
    @include typo.text-large;
    font-weight: fonts.$bold;

    @if fonts.$use-vfont {
      font-variation-settings: "wdth" 100;
    }
  }

  .c-testimony__vcard {
    margin: 0;
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-testimony {
    break-inside: avoid;
  }
}
