/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Media
 * Type: Objects
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "sass-mq/mq";
@use "../settings/spacings";
@use "../tools/global";

/*******************************************************************************
 * Base
*******************************************************************************/

.o-media {
  @include global.fluid-size(spacings.size(4), spacings.size(12), --media-gap);
}

  .o-media__figure {
    box-sizing: content-box;
    margin-bottom: var(--media-gap);

    > * {
      box-sizing: border-box;
    }
  }

  .o-media__richtext {}

/*******************************************************************************
 * Media Queriess
*******************************************************************************/

@include mq.mq($from: medium) {
  .o-media__figure {
    float: left;
    width: 40%;
    max-width: rem.convert(480px);
    margin-right: rem.convert(spacings.size(12));
  }
}

/**
 * Modifiers
**********************************************************************************/

// Right
.o-media--right {
  @include mq.mq($from: medium) {
    .o-media__figure {
      order: 1;
      float: right;
      margin-right: 0;
      margin-left: rem.convert(spacings.size(12));
    }
  }
}

// Columned
.o-media--columned {
  @include mq.mq($from: medium) {
    display: flex;
    align-items: flex-start;

    .o-media__figure {
      flex: 0 0 auto;
      float: none;
      margin-bottom: 0;
    }

    .o-media__richtext {
      flex: 1 1 auto;
      float: none;
    }

    &.o-media--right {
      justify-content: flex-end;
    }
  }

  &.o-media--middle {
    @include mq.mq($from: medium) {
      align-items: center;
    }
  }
}

.o-media--large {
  @include mq.mq($from: medium) {
    .o-media__figure {
      max-width: rem.convert(640px);
    }
  }

  @include mq.mq($from: large) {
    .o-media__figure {
      width: 50%;
    }
  }

  @include mq.mq($from: huge) {
    .o-media__figure {
      width: 60%;
    }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .o-media {
    break-inside: avoid;
  }
}
