/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Breadcrumbs
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";
@use "sass-em/em";
@use "sass-rem/rem";
@use "sass-mq/mq" as *;
@use "../settings/fonts";
@use "../settings/colors";
@use "../settings/spacings";
@use "@lunaweb/vega-reactor-scss/src/tools/objects";

/*******************************************************************************
 * Variables
*******************************************************************************/

$breadcrumbs-font-size: fonts.$small !default;
$breadcrumbs-gap: spacings.size(1) !default;
$breadcrumbs-icon-size: 1em;
$breadcrumbs-min-shrink: 24px;
$breadcrumbs-has-ellipsis: false !default;
$breadcrumbs-has-expandable: false !default;
$breadcrumbs-has-responsive: false !default;

@if $breadcrumbs-has-responsive {
  $breadcrumbs-has-expandable: true;
}

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-breadcrumbs {
  --breadcrumbs-align: normal;

  position: relative;
  font-size: rem.convert($breadcrumbs-font-size);
}

  @if $breadcrumbs-has-expandable {
    .c-breadcrumbs__control {
      display: none;
      padding: 0;
      margin: 0;
      font: inherit;
      text-decoration-line: underline;
      cursor: pointer;
      color: inherit;
      background-color: transparent;
      border: 0;
    }
  }

  .c-breadcrumbs__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: var(--breadcrumbs-align);
    gap: em.convert($breadcrumbs-gap, $breadcrumbs-font-size);
    padding: 0;
    margin: 0;
    list-style: none;

    &[hidden] {
      display: none;
    }
  }

    .c-breadcrumbs__item {
      display: inline-flex;
      flex: 0 0 auto;
      align-items: baseline;
      gap: em.convert($breadcrumbs-gap, $breadcrumbs-font-size);
      max-width: 100%;
    }

      .c-breadcrumbs__control,
      .c-breadcrumbs__link {
        font-weight: fonts.$bold;

        &:hover {
          text-decoration-color: transparent;
        }
      }

      .c-breadcrumbs__label {}

      .c-breadcrumbs__icon {
        align-self: center;
        font-size: em.convert($breadcrumbs-icon-size, $breadcrumbs-font-size);
        color: colors.get("primary-500");
      }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

// Truncate label

@if $breadcrumbs-has-ellipsis {
  .c-breadcrumbs--ellipsis {
    .c-breadcrumbs__items {
      flex-wrap: nowrap;
    }

      .c-breadcrumbs__item:not(.c-breadcrumbs__item--current) {
        display: inline-flex;
        flex: 0 1 auto;
        min-width:
          em.convert(
            $breadcrumbs-min-shrink + $breadcrumbs-gap + $breadcrumbs-icon-size,
            $breadcrumbs-font-size
          );
        overflow: hidden;
        white-space: nowrap;

        .c-breadcrumbs__link {
          flex-shrink: 1;
          min-width: em.convert($breadcrumbs-min-shrink, $breadcrumbs-font-size);
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
  }
}

// Expandable (all resolutions)

@if $breadcrumbs-has-expandable {
  .c-breadcrumbs--expandable {
    html.no-js & .c-breadcrumbs__items {
      display: flex;
      flex-wrap: nowrap;
      overflow: auto;
    }

    html.js &:not(.is-mounted):not(.has-ended) {
      .c-breadcrumbs__items {
        display: none;
      }
    }

    html.js & {
      .c-breadcrumbs__control {
        display: inline-block;
      }
    }
  }
}

// Expandable Responsive (small resolutions)

@if $breadcrumbs-has-expandable and $breadcrumbs-has-responsive {
  .c-breadcrumbs--expandable.c-breadcrumbs--responsive.is-mounted {
    @include mq($from: small) {
      .c-breadcrumbs__control {
        display: none;
      }
    }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-breadcrumbs {
    display: none;
  }
}
