/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Page
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/spacings";
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > * {
    flex: 0 0 auto;
    width: 100%;
  }
}

  .o-page__skiplinks {
    z-index: 40;
  }

  .o-page__notice {
    position: relative;
    z-index: 30;
  }

  .o-page__header {
    position: sticky;
    top: 0;
    top: calc(-1 * var(--header-top-height, 0));
    z-index: 30;

    .admin-bar & {
      top: rem.convert(50px);
      top: calc(-1 * var(--header-top-height, 0) + #{ rem.convert(50px) });
    }
  }

  .o-page__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .o-page__prefooter {
    position: relative;
    z-index: 10;
  }

  .o-page__footer {
    position: relative;
    z-index: 10;
    padding: rem.convert(spacings.size(6)) 0;
  }

  .o-page__modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }

/*******************************************************************************
 * States
*******************************************************************************/

.o-page.has-overlay {
  &::before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    background-color: currentcolor;
    opacity: 0.25;
    animation: page-overlay-open animation.$transition-duration animation.$transition-easing;
  }
}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes page-overlay-open {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.25;
  }
}


/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .o-page__header,
  .o-page__skiplinks,
  .o-page__notice,
  .o-page__prefooter,
  .o-page__footer {
    display: none;
  }
}
