/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Showcase
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";
@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/spacings";
@use "../tools/global";
@use "../tools/typography" as typo;
@use "../objects/wrapper";
@use "@lunaweb/vega-reactor-scss/src/tools/objects";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-showcase {
  container-name: showcase;
  container-type: inline-size;

  position: relative;
  overflow: hidden;
}

  .c-showcase__wrapper {
    @include objects.wrapper($gutter: 0);

    &::before {
      content: "";
      position: absolute;
      top: rem.convert(150px);
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -2;
      background-color: colors.get("primary-50");
      border-radius: rem.convert(0 20px 20px 0);
    }
  }

    .c-showcase__head {
      position: relative;
      padding: rem.convert(spacings.size(8)) 0 rem.convert(spacings.size(12));
    }

      .c-showcase__main {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: rem.convert(spacings.size(6));
        padding: rem.convert(spacings.size(8)) 0;

        > * {
          padding-right: rem.convert(wrapper.$gutter);
          padding-left: rem.convert(wrapper.$gutter);
        }
      }

        .c-showcase__heading {
          @include typo.text-large;

          > p {
            max-width: rem.convert(400px);
          }
        }

        .c-showcase__search {
          padding-right: rem.convert(spacings.size(5));
          padding-left: rem.convert(spacings.size(5));
        }

          .c-showcase__panel {
            --panel-border: transparent;
          }

      .c-showcase__aside {
        position: relative;
        z-index: 0;
        max-width: rem.convert(640px);
        margin: rem.convert(spacings.size(-28)) auto 0;
        overflow: hidden;
        border-radius: rem.convert(20px);
      }

        .c-showcase__credit {}

      .c-showcase__gimmick {
        position: absolute;
        right: clamp(rem.convert(-175px), -9.5cqw, rem.convert(-30px));
        bottom: rem.convert(spacings.size(-4));
        z-index: 0;
        font-size: clamp(rem.convert(190px), (60cqw * math.div(96, 81)), rem.convert(1137px));
        color: colors.get("primary-500");
        pointer-events: none;
      }

    .c-showcase__body {
      padding: rem.convert(wrapper.$gutter);

      > * {
        margin-top: 0;
        margin-bottom: 0;

        + * {
          margin-top: clamp(#{ rem.convert(spacings.size(4)) }, 5cqw, #{ rem.convert(spacings.size(12)) });
        }
      }
    }

      .c-showcase__title {
        max-width: rem.convert(600px);
      }

      .c-showcase__items {
        position: relative;
        z-index: 1;
        display: grid;
        padding: 0;
        list-style: none;
        gap: rem.convert(spacings.size(4));
        grid-template-columns: 1fr;
      }

        .c-showcase__item {
          display: flex;
          flex-direction: column;
          height: 100%;

          > * {
            height: 100%;
          }
        }

/*******************************************************************************
 * Container Queries
*******************************************************************************/

@container showcase (min-width: 32em) {
  .c-showcase__head {
    position: static;
  }

    .c-showcase__items {
      grid-template-columns: 1fr 1fr;
      padding-bottom: rem.convert(spacings.size(8));
    }

    .c-showcase__item {
      &:nth-child(2n) {
        transform: translateY(rem.convert(spacings.size(8)));
      }
    }
}

@container showcase (min-width: 48em) {
  .c-showcase__wrapper {
    --gutter: #{ rem.convert(wrapper.$gutter) };

    &::before {
      top: rem.convert(220px);
    }
  }

    .c-showcase__head {
      display: flex;
      align-items: center;
    }

      .c-showcase__main {
        flex: 0 0 calc(65% - #{ rem.convert(spacings.size(8)) });
      }

        .c-showcase__heading,
        .c-showcase__search {
          padding-right: 0;
          padding-left: 0;
        }

          .c-showcase__panel {
            --panel-border: transparent;
          }

        .c-showcase__heading {
          max-width: rem.convert(480px);
          padding-right: rem.convert(spacings.size(16));
        }

      .c-showcase__aside {
        margin: 0 0 0 rem.convert(spacings.size(-12));
      }

    .c-showcase__body {
      padding-right: 0;
      padding-left: 0;
    }
}

@container showcase (min-width: 56em) {
    .c-showcase__main {
      flex-basis: calc(50% - #{ rem.convert(spacings.size(6)) });
    }

      .c-showcase__heading {
        max-width: rem.convert(480px);
      }

    .c-showcase__aside {}

  .c-showcase__body {
    padding-bottom: rem.convert(spacings.size(20));
  }

    .c-showcase__items {
      grid-template-columns: 1fr 1fr 1fr;
      padding-bottom: rem.convert(spacings.size(16));
    }

      .c-showcase__item {
        &:nth-child(3n + 1) {
          transform: translateY(0);
        }

        &:nth-child(3n + 2) {
          transform: translateY(rem.convert(spacings.size(8)));
        }

        &:nth-child(3n) {
          transform: translateY(rem.convert(spacings.size(16)));
        }
      }
}

@container showcase (min-width: 92em) {
  .c-showcase__wrapper {
    &::before {
      top: rem.convert(spacings.size(62));
      right: rem.convert(spacings.size(25));
    }
  }

    .c-showcase__heading {
      padding-right: 0;

      > h1 {
        @include typo.h0;
      }
    }
}

/*******************************************************************************
 * States
*******************************************************************************/

body.is-mounted .c-showcase {
  &::before {
    @supports (clip-path: url(#showcase-line-head)) {
      content: "";
      position: absolute;
      @include global.fluid-size(210px, 540px, top);
      left: 50%;
      z-index: -1;
      width: 100%;
      min-width: rem.convert(1600px);
      height: rem.convert(164px);
      background-color: colors.get("primary-300");
      transform: translateX(-50%);
      clip-path: url(#showcase-line-head);
    }
  }

  .c-showcase__items {
    &::before {
      @supports (clip-path: url(#showcase-line-body)) {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        width: 100vw;
        min-width: rem.convert(1600px);
        height: rem.convert(515px);
        background-color: colors.get("primary-300");
        transform: translate(-50%, -50%);
        clip-path: url(#showcase-line-body);
      }
    }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-showcase_head,
  .c-showcase_body {
    break-inside: avoid;
  }
}
