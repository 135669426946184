/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Sides
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/spacings";

/*******************************************************************************
 * Variables
*******************************************************************************/

$sides-min-width: 420px !default;
$sides-min-ratio: 50% !default;
$sides-gap: spacings.size(6) !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-sides {
  --min-width: #{ rem.convert($sides-min-width) };
  --min-ratio: #{ $sides-min-ratio };
  --gap: #{ rem.convert($sides-gap) };

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--gap);

  > * {
    margin: 0;
  }
}

  .o-sides__bar {
    flex-basis: var(--min-width);
    flex-grow: 1;
    width: var(--min-width);
    max-width: 100%;
  }

  .o-sides__main {
    flex-basis: 0;
    flex-grow: 999;
    min-width: var(--min-ratio);
  }

/*******************************************************************************
 * Variants
*******************************************************************************/

.o-sides--middle {
  align-items: center;
}
