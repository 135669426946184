/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Alert
 * Type: Component
 * Description: Display an informative message.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/fonts";
@use "../settings/colors";
@use "../settings/spacings";

/*******************************************************************************
 * Variables
*******************************************************************************/

$alert-themes: (
  "neutral": (
    "background": #{ colors.get("neutral-50") },
    "accent-color": #{ colors.get("black") },
  ),
  "success": (
    "background": #{ colors.get("success-50") },
    "accent-color": #{ colors.get("success") },
  ),
  "warning": (
    "background": #{ colors.get("warning-50") },
    "accent-color": #{ colors.get("warning") },
  ),
  "error": (
    "background": #{ colors.get("error-50") },
    "accent-color": #{ colors.get("error") },
  ),
);

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-alert {
  --alert-background: #{ colors.get("primary-50") };
  --alert-accent-color: #{ colors.get("primary-500") };

  position: static;
  display: flex;
  padding: rem.convert(spacings.size(4));
  font-size: rem.convert(fonts.$small);
  color: colors.get("black");
  background: var(--alert-background);
  border-left: rem.convert(6px) solid var(--alert-accent-color);
  border-radius: rem.convert(4px);
  box-shadow: rem.convert(0 4px 16px -4px) rgba(3, 0, 138, 0.2);
}

  .c-alert__icon {
    flex: 0 0 auto;
    margin-top: rem.convert(2px);
    margin-right: rem.convert(8px);
    font-size: rem.convert(18px);
    color: var(--alert-accent-color);
  }

  .c-alert__content {
    --alert-content-spacing: #{ rem.convert(8px) };

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: var(--alert-content-spacing);
    }
  }

    .c-alert__actions {
      --alert-actions-spacing: #{ rem.convert(8px) };

      display: flex;
      flex-wrap: wrap;
      margin-top: calc(var(--alert-content-spacing) - var(--alert-actions-spacing) * 0.5);
      margin-right: calc(var(--alert-actions-spacing) * -0.5);
      margin-bottom: calc(var(--alert-actions-spacing) * -0.5);
      margin-left: calc(var(--alert-actions-spacing) * -0.5);
    }

      .c-alert__action {
        padding: calc(var(--alert-actions-spacing) * 0.5);
      }

/*******************************************************************************
 * Type modifiers
*******************************************************************************/

@each $type, $theme in $alert-themes {
  .c-alert--#{$type} {
    @each $key, $value in $theme {
      --alert-#{$key}: #{$value};
    }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-alert {
    break-inside: avoid;
    border: rem.convert(1px) solid #000 !important;
    border-left-width: rem.convert(6px) !important;
  }
}
