/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Expander
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "../settings/spacings";
@use "../tools/global";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-expander {
  @include global.fluid-size(spacings.size(6), spacings.size(12), --space);

  display: flex;
  flex-direction: column;
  gap: var(--space);
}

  .o-expander__item,
  .o-expander__control {
    &[hidden] {
      display: none;
    }
  }
