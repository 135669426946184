/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Avatar
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "sass-em/em";
@use "../settings/colors";

/*******************************************************************************
 * Variables
*******************************************************************************/

$avatar-font-size: 100px;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-avatar {
  --avatar-color: #{ colors.get("primary") };
  --avatar-background: #{ colors.get("primary-50") };

  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  overflow: hidden;
  font-size: rem.convert($avatar-font-size);
  line-height: 0;
  color: var(--avatar-color);
  background-color: var(--avatar-background);
  border-radius: 1em;
}

  .c-avatar__placeholder {
    display: block;

    &::before,
    &::after {
      content: "";
      position: absolute;
      background-color: var(--avatar-color);
    }

    &::before {
      top: 40%;
      left: 50%;
      width: em.convert(35px, $avatar-font-size);
      height: em.convert(35px, $avatar-font-size);
      border-radius: 1em;
      transform: translate(-50%, -50%);
    }

    &::after {
      top: 100%;
      left: 50%;
      width: em.convert(75px, $avatar-font-size);
      height: em.convert(75px, $avatar-font-size);
      border-radius: 1em;
      transform: translate(-50%, -50%);
    }
  }

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-avatar {
    break-inside: avoid;
  }
}
