/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Map
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-map {
  container-name: map;
  container-type: inline-size;

  position: relative;
}

  .c-map__illustration {
    width: 100%;
    height: auto;
  }

  .c-map__items {
    padding: 0;
    margin: 0;
    list-style: none;
  }

    .c-map__item {
      --map-x: 0;
      --map-y: 0;

      position: absolute;
      top: calc(1% * var(--map-y));
      left: calc(1% * var(--map-x));
      display: flex;
      transform: translate(-50%, -100%);
    }

      .c-map__marker {
        font-size: 5.92cqw;
      }

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-map {
    break-inside: avoid;
  }
}
