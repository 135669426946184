/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Logo
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";
@use "@lunaweb/vega-reactor-scss/src/tools/hide";
@use "../settings/colors";

/*******************************************************************************
 * Variables
*******************************************************************************/

$logo-ratio: #{ math.div(170, 64) };
$logo-gimmick-ratio: #{ math.div(128, 128) };

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-logo {
  --logo-ratio: #{ $logo-ratio };
  --logo-color: inherit;
  --logo-fill: inherit;

  display: inline-block;
  line-height: 0;
  color: var(--logo-color);
}

  .c-logo__illustration {
    width: calc(1em * var(--logo-ratio));
    height: 1em;
  }

  .c-logo__label {
    @include hide.visually;
  }

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-logo--gimmick {
  --logo-ratio: #{ $logo-gimmick-ratio };
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-logo {
    break-inside: avoid;
  }
}
