/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Root
 * Type: Generic
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";
@use "sass-rem/rem";
@use "sass-mq/mq";
@use "../settings/global";
@use "../settings/colors";

/*******************************************************************************
 * Default styles
*******************************************************************************/

:root {
  --vh-offset: 0px;
  --navigation-height: 0px;
  --header-top-height: 0px;
  --header-main-height: #{ rem.convert(60px) };
  --header-height: #{ rem.convert(60px) };
  --header-global-height: #{ rem.convert(60px) };

  @each $name, $value in colors.$all {
    --color-#{ $name }: #{ $value };
  }

  @include mq.mq($from: map.get(global.$breakpoints, "navigation")) {
    --navigation-height: #{ rem.convert(80px) };
    --header-top-height: #{ rem.convert(45px) };
    --header-main-height: #{ rem.convert(96px) };
    --header-height: #{ rem.convert(141px) };
    --header-global-height: #{ rem.convert(221px) };
  }
}

@-ms-viewport { width: device-width; }
