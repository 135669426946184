/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Note
 * Type: Component
 * Description:
 *  An informative message indicating instructions to the user to paired with an
 *  input.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../../settings/fonts";
@use "../../settings/colors";

/*******************************************************************************
 * Variables
*******************************************************************************/

$note-colors: (
  "help": #{ colors.get("foreground") },
  "info": #{ colors.get("primary") },
  "success": #{ colors.get("success") },
  "warning": #{ colors.get("warning") },
  "error": #{ colors.get("error") },
);

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-note {
  display: flex;
  align-items: baseline;
  font-size: rem.convert(fonts.$small);
}

  .c-form-note__icon {
    flex: 0 0 auto;
    margin-right: rem.convert(8px);
    font-size: rem.convert(fonts.$base);
  }

  .c-form-note__text {
    flex: 1 1 auto;
    margin: 0;
    font-size: rem.convert(fonts.$small);
  }

/*******************************************************************************
 * Type modifiers
*******************************************************************************/

.c-form-note--info,
.c-form-note--success,
.c-form-note--warning,
.c-form-note--error {
  font-weight: fonts.$bold;
}

@each $type, $color in $note-colors {
  .c-form-note--#{$type} { color: $color; }
}
