/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Fonts
 * Type: Generic
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "../settings/fonts" as font;
@use "../tools/fonts";

/*******************************************************************************
 * Default styles
*******************************************************************************/

@if font.$use-vfont {
  @supports (font-variation-settings: "wdth" 125) {
    @font-face {
      font-display: swap;
      font-family: "Anek Latin Variable";
      font-weight: 100 900;
      font-stretch: 100 125;
      src: url("#{font.$folder}AnekLatin-subset.woff");
      src:
        url("#{font.$folder}AnekLatin-subset.woff2")  format("woff2"),
        url("#{font.$folder}AnekLatin-subset.woff")   format("woff");
    }
  }
}

/**
* Normal
*******************************************************************************/

// @include
//   fonts.font-face(
//     "Anek Latin",
//     "AnekLatin-Thin",
//     font.$thin,
//     normal,
//     $suffix: "-subset"
// );

// @include
//   fonts.font-face(
//     "Anek Latin",
//     "AnekLatin-ExtraLight",
//     font.$extralight,
//     normal,
//     $suffix: "-subset"
// );

// @include
//   fonts.font-face(
//     "Anek Latin",
//     "AnekLatin-Light",
//     font.$light,
//     normal,
//     $suffix: "-subset"
// );

@include
  fonts.font-face(
    "Anek Latin",
    "AnekLatin-Regular",
    font.$regular,
    normal,
    $suffix: "-subset"
);

// @include
//   fonts.font-face(
//     "Anek Latin",
//     "AnekLatin-Medium",
//     font.$medium,
//     normal,
//     $suffix: "-subset"
// );

@include
  fonts.font-face(
    "Anek Latin",
    "AnekLatin-SemiBold",
    font.$semibold,
    normal,
    $suffix: "-subset"
);

@include
  fonts.font-face(
    "Anek Latin",
    "AnekLatin-Bold",
    font.$bold,
    normal,
    $suffix: "-subset"
);

// @include
//   fonts.font-face(
//     "Anek Latin",
//     "AnekLatin-ExtraBold",
//     font.$extrabold,
//     normal,
//     $suffix: "-subset"
// );

// @include
//   fonts.font-face(
//     "Anek Latin",
//     "AnekLatin-Black",
//     font.$black,
//     normal,
//     $suffix: "-subset"
// );

/**
* Expanded
*******************************************************************************/

// @include
//   fonts.font-face(
//     "Anek Latin Expanded",
//     "AnekLatin_Expanded-Thin",
//     font.$thin,
//     normal,
//     $suffix: "-subset"
// );

// @include
//   fonts.font-face(
//     "Anek Latin Expanded",
//     "AnekLatin_Expanded-ExtraLight",
//     font.$extralight,
//     normal,
//     $suffix: "-subset"
// );

// @include
//   fonts.font-face(
//     "Anek Latin Expanded",
//     "AnekLatin_Expanded-Light",
//     font.$light,
//     normal,
//     $suffix: "-subset"
// );

// @include
//   fonts.font-face(
//     "Anek Latin Expanded",
//     "AnekLatin_Expanded-Regular",
//     font.$regular,
//     normal,
//     $suffix: "-subset"
// );

// @include
//   fonts.font-face(
//     "Anek Latin Expanded",
//     "AnekLatin_Expanded-Medium",
//     font.$medium,
//     normal,
//     $suffix: "-subset"
// );

// @include
//   fonts.font-face(
//     "Anek Latin Expanded",
//     "AnekLatin_Expanded-SemiBold",
//     font.$semibold,
//     normal,
//     $suffix: "-subset"
// );

@include
  fonts.font-face(
    "Anek Latin Expanded",
    "AnekLatin_Expanded-Bold",
    font.$bold,
    normal,
    $suffix: "-subset"
);

// @include
//   fonts.font-face(
//     "Anek Latin Expanded",
//     "AnekLatin_Expanded-ExtraBold",
//     font.$extrabold,
//     normal,
//     $suffix: "-subset"
// );

// @include
//   fonts.font-face(
//     "Anek Latin Expanded",
//     "AnekLatin_Expanded-Black",
//     font.$black,
//     normal,
//     $suffix: "-subset"
// );
