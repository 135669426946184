/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Skiplinks
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";
@use "sass-em/em";
@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";
@use "@lunaweb/vega-reactor-scss/src/tools/objects";

/*******************************************************************************
 * Variables
*******************************************************************************/

$skiplinks-font-size: fonts.$small !default;
$skiplinks-gap: 24px !default;
$skiplinks-has-focus-behaviour: true !default;
$skiplinks-background: #{ colors.get("background-revert") };
$skiplinks-foreground: #{ colors.get("foreground-revert") };

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-skiplinks {
  position: relative;
  padding: em.convert($skiplinks-gap, $skiplinks-font-size);
  font-size: rem.convert($skiplinks-font-size);
  color: $skiplinks-foreground;
  background-color: $skiplinks-background;
}

  .c-skiplinks__navigation {
    @include objects.wrapper;

    display: flex;
    gap: em.convert($skiplinks-gap, $skiplinks-font-size);
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

    .c-skiplinks__items {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: em.convert(math.div(-$skiplinks-gap, 4), $skiplinks-font-size) em.convert(math.div(-$skiplinks-gap, 2), $skiplinks-font-size);
      list-style: none;
    }

      .c-skiplinks__item {
        display: inline-block;
        padding: em.convert(math.div($skiplinks-gap, 4), $skiplinks-font-size) em.convert(math.div($skiplinks-gap, 2), $skiplinks-font-size);
      }

      .c-skiplinks__link {
        color: inherit;
      }

      .c-skiplinks__action {
        --button-icon-color: #{ colors.get("secondary") };
      }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

@if $skiplinks-has-focus-behaviour {
  .c-skiplinks--focus {
    position: absolute;
    top: 0;
    transform: translateY(-100%);

    &:focus-within {
      position: relative;
      transform: translateY(0);
    }
  }
}
