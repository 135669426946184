/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Stack
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "../settings/spacings";
@use "../tools/global";

/*******************************************************************************
 * Variables
*******************************************************************************/

$stack-gutter: spacings.$regular !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-stack {
  --space: #{ rem.convert($stack-gutter) };

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: var(--space);
  }
}

/*******************************************************************************
 * Variants
*******************************************************************************/

.o-stack--large {
  @include global.fluid-size(spacings.size(6), spacings.size(12), --space);
}
