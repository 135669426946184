/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Marker
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-em/em";
@use "sass-rem/rem";
@use "../settings/colors";
@use "../settings/fonts";

/*******************************************************************************
 * Variables
*******************************************************************************/

$marker-font-size: 54px;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-marker {
  position: relative;
  display: inline-block;
  width: 0.8333em;
  height: 1em;
  font-size: rem.convert($marker-font-size);
  line-height: 0;
  vertical-align: top;
}

  .c-marker__icon {
    width: 0.8333em;
    color: colors.get("primary-500");
    fill: colors.get("background");
  }

  .c-marker__number {
    position: absolute;
    top: em.convert(23px, fonts.$base);
    left: 50%;
    min-width: em.convert(20px, fonts.$base);
    font-size: em.convert(fonts.$base, $marker-font-size);
    font-weight: fonts.$bold;
    line-height: 1;
    text-align: center;
    color: colors.get("primary");
    transform: translate(-50%, -50%);
  }

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-marker {
    break-inside: avoid;
  }
}
