/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Colors
 * Type: Utilities
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";

/*******************************************************************************
 * Variables
*******************************************************************************/

$colors-properties: (
  // "border": "border-color",
  // "background": "background-color",
  "color": "color",
);

/*******************************************************************************
 * Default styles
*******************************************************************************/

@each $selector, $property in $colors-properties {
  [style*="--#{$selector}:"] {
    #{$property}: var(--#{$selector});
  }
}
