/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Tile
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass-rem/rem";
@use "sass-em/em";
@use "../settings/fonts";
@use "../settings/colors";
@use "../settings/spacings";
@use "../tools/global";
@use "../tools/typography" as typo;
@use "@lunaweb/vega-reactor-scss/src/tools/animation";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-tile {
  --tile-color: #{ colors.get("foreground") };
  --tile-background: #{ colors.get("background") };

  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem.convert(spacings.size(4));
  color: var(--tile-color);
  background-color: var(--tile-background);
  border: rem.convert(1px) solid colors.get("primary-100");
  border-radius: rem.convert(20px);

  &:not(.c-tile--minimalist) {
    container-name: tile;
    container-type: inline-size;
  }
}

  .c-tile__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem.convert(spacings.size(1));
    padding: rem.convert(spacings.size(1));

    > * {
      margin: 0;
    }
  }

    .c-tile__icon {
      font-size: rem.convert(48px);
      color: colors.get("primary-500");
    }

    .c-tile__title {
      --icon-color: #{ colors.get("secondary") };

      position: static;
      @include typo.title("tile");
      @include global.fluid-size(18px, 20px);
      font-weight: fonts.$bold;
      line-height: 1.2;
    }

    .c-tile__link {
      z-index: 1;
    }

/*******************************************************************************
 * Container Queries
*******************************************************************************/

@container tile (min-width: 18em) {
  .c-tile__container {
    display: grid;
    row-gap: spacings.size(2);
    column-gap: spacings.size(6);
    grid-template:
      "icon title" auto
      "icon link" auto / rem.convert(48px) 1fr;
    padding: rem.convert(spacings.size(4));
  }

  .c-tile__icon {
    grid-area: icon;
    align-self: center;
  }

  .c-tile__title {
    grid-area: title;
  }

  .c-tile__link {
    grid-area: link;
    justify-self: start;
  }
}

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-tile--interactive {
  @include animation.transition(color border-color);
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: rem.convert(-1px);
    right: rem.convert(-1px);
    bottom: rem.convert(-1px);
    left: rem.convert(-1px);
    z-index: -1;
    background-color: colors.get("primary-800");
    opacity: 0;
    transform: scaleX(0);
    transform-origin: left center;
    @include animation.transition(opacity transform);
  }

  .c-tile__icon {
    @include animation.transition(color);
  }

  &:hover {
    color: colors.get("foreground-revert");
    border-color: colors.get("primary-800");

    &::before {
      opacity: 1;
      transform: scaleX(1);
    }

    .c-tile__icon {
      color: colors.get("primary-300");
    }

    .c-tile__mainlink {
      text-decoration-color: transparent;
    }

    .c-tile__link {
      --button-color: white;
    }
  }
}

.c-tile--minimalist {
  .c-tile__container {
    padding: rem.convert(spacings.size(4));
  }

    .c-tile__title {
      font-size: rem.convert(18px);
      font-weight: fonts.$regular;

      &::after {
        content: "\203A";
        margin-left: 0.5ch;
      }
    }

      .c-tile__mainlink {
        text-decoration-line: underline;
      }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-tile {
    break-inside: avoid;
    color: #000 !important;
    background-color: #fff !important;
  }
}
